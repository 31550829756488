import { useMemo, useState, useRef, useCallback, ChangeEvent } from 'react';

import { useHistory } from 'react-router-dom';
import { CellProps } from 'react-table';
import {
  useRecoilValue,
  useRecoilValueLoadable,
  useSetRecoilState,
} from 'recoil';
import styled from 'styled-components';

import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';

import { Flexbox, Vertical } from 'src/components/Alignments';
import AddAnnotatorDialog from 'src/components/Modal/AddAnnotatorDialog';
import JobAssignDialog from 'src/components/Modal/JobAssignDialog';
import Table from 'src/components/Table';
import useDeferredState from 'src/hooks/useDeferredState';
import { jobState } from 'src/states/job';
import { projectState, useRefreshProject } from 'src/states/project';
import { useRefreshAnnotatorList, userState } from 'src/states/user';
import { JobsStatsState } from 'src/types/client/stats';
import { PathNames } from 'src/types/client/url';
import { Annotator, UserInfo } from 'src/types/client/user';
import { DEFAULT_PAGE_SIZE } from 'src/utils/constants';
import { UrlUtil } from 'src/utils/url';

const IconButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
`;

interface AnnotatorsProps {
  projectId: string;
  dbName: string;
  modality: string;
  jobStatsState?: JobsStatsState;
}

export default function Annotators({
  projectId,
  dbName,
  modality,
  jobStatsState,
}: AnnotatorsProps): JSX.Element {
  const history = useHistory();

  const project = useRecoilValue(projectState.project({ projectId, dbName }));
  const refreshProject = useRefreshProject();
  const refreshAnnotators = useRefreshAnnotatorList({ projectId, dbName });

  const targetAnnotator = useRef<Annotator>();

  const setJobUserOption = useSetRecoilState(jobState.jobUserOption);

  const annotatorsLoadable = useRecoilValueLoadable(
    userState.annotatorList({ projectId, dbName })
  );

  const annotatorList = useMemo(
    () =>
      annotatorsLoadable.state === 'hasValue'
        ? annotatorsLoadable.contents
        : [],
    [annotatorsLoadable.contents, annotatorsLoadable.state]
  );
  // Pagination
  const [page, setPage] = useState(1);
  const totalPages = Math.ceil(annotatorList.length / DEFAULT_PAGE_SIZE);

  // Controller dialogs
  const [openAddAnnotatorDialog, setOpenAddAnnotatorDialog] =
    useDeferredState(false);
  const [openJobAssignDialog, setOpenJobAssignDialog] = useDeferredState(false);

  const handleClickJobAssignPlus = useCallback(
    (annotator: Annotator) => () => {
      targetAnnotator.current = annotator;
      setOpenJobAssignDialog(true);
    },
    [setOpenJobAssignDialog]
  );

  const handleClickAddAnnotators = () => {
    setOpenAddAnnotatorDialog(true);
  };

  const handleCloseAddAnnotators = () => {
    setOpenAddAnnotatorDialog(false);
  };

  const handleCloseJobAssignDialog = () => {
    setOpenJobAssignDialog(false);
  };

  const handleUpdateJobAssign = () => {
    refreshProject();
    refreshAnnotators();
    setOpenJobAssignDialog(false);
  };

  const handleAddAnnotator = () => {
    refreshAnnotators();
    setOpenAddAnnotatorDialog(false);
  };

  const handleClickViewJob = useCallback(
    (selectedUser: UserInfo) => () => {
      setJobUserOption({
        value: selectedUser.userId,
        label: selectedUser.userName,
      });
      history.push(
        UrlUtil.getUrl(PathNames.PROJECT_TAB, {
          modalityLabel: modality,
          projectId,
          tabName: 'jobs',
        })
      );
    },
    [history, modality, projectId, setJobUserOption]
  );

  // Table elements
  const annotatorsColumnHeader = useMemo(() => {
    return [
      {
        Header: 'Annotator List',
        columns: [
          {
            Header: 'Annotator Username',
            accessor: 'username',
          },
          {
            Header: 'Annotation Progress (Done / Total)',
            id: 'progress',
            accessor: ({ jobStat: { completedStage, total } }: Annotator) =>
              `${completedStage} / ${total}`,
          },
          {
            Header: 'Corroboration Count',
            id: 'corroborationCount',
            accessor: ({ corroborationStat: { completedStage } }: Annotator) =>
              completedStage,
            style: { width: '20%' },
          },
          {
            Header: 'Assignment',
            id: 'targetUser',
            style: { width: '10%' },
            Cell: (cell: CellProps<Annotator, Annotator>) => {
              return (
                <IconButtonGroup>
                  <Button
                    variant="outlined"
                    color="primary"
                    size="small"
                    onClick={handleClickJobAssignPlus(cell.row.original)}
                    disabled={
                      project.confirmed || jobStatsState === 'noJobLeft'
                    }
                  >
                    Assign
                  </Button>
                </IconButtonGroup>
              );
            },
          },
          {
            Header: 'Jobs',
            id: 'jobs',
            style: { width: '10%' },
            Cell: function renderJobs(cell: CellProps<Annotator>) {
              return (
                <Button
                  size="small"
                  variant="outlined"
                  onClick={handleClickViewJob({
                    userId: cell.row.original.id,
                    userName: cell.row.original.username,
                  })}
                >
                  View
                </Button>
              );
            },
          },
        ],
      },
    ];
  }, [
    handleClickJobAssignPlus,
    handleClickViewJob,
    jobStatsState,
    project.confirmed,
  ]);

  const paginatedAnnotators: Annotator[] = useMemo(
    () =>
      annotatorList.slice(
        (page - 1) * DEFAULT_PAGE_SIZE,
        page * DEFAULT_PAGE_SIZE
      ),

    [annotatorList, page]
  );

  const handleChangePage = (event: ChangeEvent<unknown>, page: number) =>
    setPage(page);

  return (
    <Vertical>
      <Flexbox>
        <Button
          color="primary"
          variant="contained"
          onClick={handleClickAddAnnotators}
          disabled={project.confirmed}
          startIcon={<AddIcon />}
          style={{ width: 150 }}
        >
          Annotators
        </Button>
      </Flexbox>
      <Table<Annotator>
        columns={annotatorsColumnHeader}
        data={paginatedAnnotators}
        isLoading={annotatorsLoadable.state === 'loading'}
        isError={annotatorsLoadable.state === 'hasError'}
        onRefresh={refreshAnnotators}
        paginationProps={{
          page,
          count: totalPages,
          onChange: handleChangePage,
        }}
      />
      <AddAnnotatorDialog
        open={openAddAnnotatorDialog}
        onUpdate={handleAddAnnotator}
        onClose={handleCloseAddAnnotators}
        projectId={projectId}
        dbName={dbName}
      />
      <JobAssignDialog
        open={openJobAssignDialog}
        annotatorName={targetAnnotator.current?.username}
        annotatorId={targetAnnotator.current?.id}
        projectId={projectId}
        assignableJobCount={project.jobStats.annotation.createdStage}
        onClose={handleCloseJobAssignDialog}
        onUpdate={handleUpdateJobAssign}
        dbName={dbName}
      />
    </Vertical>
  );
}
