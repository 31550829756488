import { INITIAL_CLAIM } from 'src/configs/claims';
import {
  CLAIM_CONTROLS_DBT,
  CLAIM_CONTROL_MULTI_FRAME_POLYGON,
  CLAIM_CONTROL_POLYGON,
} from 'src/configs/claims/controls';
import { ClaimCreateSchema } from 'src/types/api/data/project';

/* eslint-disable no-template-curly-in-string */
const DBTClaim: ClaimCreateSchema = {
  ...INITIAL_CLAIM,
  controls: [
    CLAIM_CONTROL_MULTI_FRAME_POLYGON,
    CLAIM_CONTROL_POLYGON,
    ...CLAIM_CONTROLS_DBT,
  ],
  viewer: {
    type: 'tomosynthesis',
    images: [
      {
        name: 'RCC_2D',
        id: 'RCC_2D._id',
        type: 'RCC_2D.type',
        path: 'RCC_2D.path',
        width: 'RCC_2D.Columns',
        height: 'RCC_2D.Rows',
      },
      {
        name: 'LCC_2D',
        id: 'LCC_2D._id',
        type: 'LCC_2D.type',
        path: 'LCC_2D.path',
        width: 'LCC_2D.Columns',
        height: 'LCC_2D.Rows',
      },
      {
        name: 'RMLO_2D',
        id: 'RMLO_2D._id',
        type: 'RMLO_2D.type',
        path: 'RMLO_2D.path',
        width: 'RMLO_2D.Columns',
        height: 'RMLO_2D.Rows',
      },
      {
        name: 'LMLO_2D',
        id: 'LMLO_2D._id',
        type: 'LMLO_2D.type',
        path: 'LMLO_2D.path',
        width: 'LMLO_2D.Columns',
        height: 'LMLO_2D.Rows',
      },
      {
        name: 'RCC_3D',
        id: 'RCC_3D._id',
        type: 'RCC_3D.type',
        path: 'RCC_3D.paths',
        width: 'RCC_3D.Columns',
        height: 'RCC_3D.Rows',
      },
      {
        name: 'LCC_3D',
        id: 'LCC_3D._id',
        type: 'LCC_3D.type',
        path: 'LCC_3D.paths',
        width: 'LCC_3D.Columns',
        height: 'LCC_3D.Rows',
      },
      {
        name: 'RMLO_3D',
        id: 'RMLO_3D._id',
        type: 'RMLO_3D.type',
        path: 'RMLO_3D.paths',
        width: 'RMLO_3D.Columns',
        height: 'RMLO_3D.Rows',
      },
      {
        name: 'LMLO_3D',
        id: 'LMLO_3D._id',
        type: 'LMLO_3D.type',
        path: 'LMLO_3D.paths',
        width: 'LMLO_3D.Columns',
        height: 'LMLO_3D.Rows',
      },
    ],
    preview: {
      polygon: "`${alias('polygonType')} [${index}]`",
      line: '',
      point: '',
    },
  },
};

export default DBTClaim;
