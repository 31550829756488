import { ClaimCreateSchema } from 'src/types/api/data/project';

import { CLAIM_CONTROLS } from './controls';

export const MODES = [
  {
    name: 'annotation',
    text: 'ANNOTATION',
    type: 'pass',
    isEditable: true,
  },
  {
    name: 'prediction',
    text: 'AI PREDICTION',
    type: 'prediction',
    isEditable: false,
  },
  {
    name: 'last',
    text: 'LAST ANNOTATION',
    type: 'last_annotation',
  },
] as const;

export const INITIAL_ASSIGN_RULE = {
  corroboration: {
    probability: 0,
    max: 0,
    finishWhenAnnotationDone: false,
  },
};

export const INITIAL_CLAIM: ClaimCreateSchema = {
  modes: [MODES[0]],
  assets: [],
  controls: CLAIM_CONTROLS,
  viewer: {
    type: 'xray',
    images: [],
    preview: { polygon: '', line: '', point: '' },
  },
  version: '1.1',
  findingFields: [],
  assignRule: INITIAL_ASSIGN_RULE,
  overrides: [],
  type: 'Standard',
};
