import { ReportReadSchema, ReportListSchema } from 'src/types/api/data/report';
import { SelectOption } from 'src/types/client/ui';
import { DEFAULT_PAGE_SIZE } from 'src/utils/constants';
import { dataApi } from 'src/utils/http';

export type GetReportListProps = Readonly<{
  jobId?: string;
  resolvedOnly?: boolean;
  includeResolved?: boolean;
  dateFrom?: string;
  dateTo?: string;
  sort?: string | string[];
  page: number;
  pageSize?: number;
  databaseOption: SelectOption | null;
  userOption?: SelectOption;
}>;

export const getReportList = async ({
  jobId,
  resolvedOnly,
  includeResolved,
  dateFrom,
  dateTo,
  sort = '-createdAt',
  page,
  pageSize = DEFAULT_PAGE_SIZE,
  databaseOption,
  userOption,
}: GetReportListProps): Promise<ReportListSchema> => {
  const userId = userOption?.value === 'all' ? undefined : userOption?.value;
  const { data } = await dataApi.get<ReportListSchema>(`/reports/`, {
    params: {
      job_id: jobId,
      user: userId,
      resolved_only: resolvedOnly,
      include_resolved: includeResolved,
      date_from: dateFrom,
      date_to: dateTo,
      sort,
      page,
      page_size: pageSize,
      database_name: databaseOption?.value,
    },
  });

  return data;
};

export const getReportById = async (
  reportId: string
): Promise<ReportReadSchema> => {
  const { data } = await dataApi.get<ReportReadSchema>(`/reports/${reportId}/`);

  return data;
};

interface PatchReportProps {
  reportId: string;
  resolved: boolean;
}

export const patchReport = async ({
  reportId,
  resolved,
}: PatchReportProps): Promise<ReportReadSchema> => {
  const { data } = await dataApi.patch(`/reports/${reportId}/`, {
    resolved,
  });

  return data;
};

export const DEFAULT_REPORT: ReportReadSchema = {
  id: '',
  text: '',
  job: '',
  user: '',
  resolved: false,
  createdAt: '2021-01-01T00:00:00Z',
};
