import { MouseEventHandler, ChangeEvent, memo } from 'react';

import { ClaimCategorySchema } from '@lunit-io/radiology-data-interface';

import Close from '@mui/icons-material/Close';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

import KeyNameAutoFillButton from './KeyNameAutoFillButton';

type OwnProps = {
  isDeletable: boolean;
  category: ClaimCategorySchema;
  removeCategory: MouseEventHandler<HTMLButtonElement>;
  onChange: (key: keyof ClaimCategorySchema, value: string) => void;
  readonly: boolean;
};

const AssetCategoryRow = ({
  isDeletable,
  category: { name, text, alias },
  removeCategory,
  onChange,
  readonly,
}: OwnProps): JSX.Element => {
  const getHandleChange =
    (key: keyof ClaimCategorySchema) =>
    (event: ChangeEvent<HTMLInputElement>) => {
      onChange(key, event.target.value);
    };

  const handleClickAutoFill = (name: string) => {
    onChange('name', name);
  };

  return (
    <StyledFormGroup>
      <FormControl size="small" sx={{ flex: 1 }}>
        <TextField
          value={text}
          onChange={getHandleChange('text')}
          id="category-text"
          size="small"
          label="Display Text"
          required
          disabled={readonly}
        />
      </FormControl>
      <FormControl size="small" sx={{ flex: 1 }}>
        <TextField
          value={name}
          onChange={getHandleChange('name')}
          id="category-name"
          size="small"
          label="Database Value"
          required
          disabled={readonly}
          InputProps={{
            endAdornment: !readonly && (
              <KeyNameAutoFillButton
                text={text}
                onClick={handleClickAutoFill}
              />
            ),
          }}
        />
      </FormControl>
      <FormControl size="small">
        <TextField
          value={alias || ''}
          onChange={getHandleChange('alias')}
          id="category-alias"
          size="small"
          label="Alias"
          disabled={readonly}
        />
      </FormControl>
      <Tooltip title="Remove this item" placement="right">
        <IconButton
          aria-label="Delete this item"
          onClick={removeCategory}
          disabled={readonly || !isDeletable}
          size="small"
        >
          <Close />
        </IconButton>
      </Tooltip>
    </StyledFormGroup>
  );
};

export default memo(AssetCategoryRow);

const StyledFormGroup = styled(FormGroup)(
  ({ theme }) => `
  display: flex;
  flex-direction: row;
  gap: ${theme.spacing(1)};
`
);
