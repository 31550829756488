import { useState, useEffect, useMemo } from 'react';

import {
  Home,
  Users,
  Briefcase,
  Search,
  Eye,
  Icon,
  AlertTriangle,
  List as ListIcon,
} from 'react-feather';
import { Link, useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import { styled } from '@mui/material/styles';

import { myInfoState } from 'src/states/myInfo';
import { PathNames } from 'src/types/client/url';
import { UrlUtil } from 'src/utils/url';

import MenuItem from './MenuItem';
import UserMenuList from './UserMenuList';

const SideBar = (): JSX.Element => {
  const [currentPathName, setCurrentPathName] = useState<PathNames>(
    PathNames.HOME
  );
  const { pathname } = useLocation();
  const user = useRecoilValue(myInfoState);

  const menus = useMemo<{
    [key in Extract<
      PathNames,
      | PathNames.HOME
      | PathNames.USERS
      | PathNames.PROJECTS
      | PathNames.CASE_SEARCH
      | PathNames.AI_OPERATIONAL
      | PathNames.REPORTS
      | PathNames.ASSET_TEMPLATES
    >]: { to: string; IconComponent: Icon; disabled?: boolean };
  }>(() => {
    const modalityLabel =
      user.DBAuthorizationList.map(({ modality }) => modality)[0] || 'CXR';

    return {
      [PathNames.HOME]: {
        to: UrlUtil.getUrl(PathNames.HOME),
        IconComponent: Home,
      },
      [PathNames.USERS]: {
        to: UrlUtil.getUrl(PathNames.USERS),
        IconComponent: Users,
      },
      [PathNames.PROJECTS]: {
        to: UrlUtil.getUrl(PathNames.PROJECTS, { modalityLabel }),
        IconComponent: Briefcase,
      },
      [PathNames.CASE_SEARCH]: {
        to: UrlUtil.getUrl(PathNames.CASE_SEARCH, { modalityLabel }),
        IconComponent: Search,
      },
      [PathNames.AI_OPERATIONAL]: {
        to: UrlUtil.getUrl(PathNames.AI_OPERATIONAL, { modalityLabel }),
        IconComponent: Eye,
      },
      [PathNames.REPORTS]: {
        to: UrlUtil.getUrl(PathNames.REPORTS, { modalityLabel }),
        IconComponent: AlertTriangle,
      },
      [PathNames.ASSET_TEMPLATES]: {
        to: UrlUtil.getUrl(PathNames.ASSET_TEMPLATES, { modalityLabel }),
        IconComponent: ListIcon,
      },
    };
  }, [user]);

  useEffect(() => {
    setCurrentPathName(UrlUtil.getMatchedPathName(pathname));
  }, [pathname]);

  return (
    <Sidebar>
      <Heading>
        CTL Office <Version>v0.1.0</Version>
      </Heading>
      <SubHeading>Closing-the-Loop for INSIGHT</SubHeading>
      <MenuList className="sidebar-menu-list">
        {(Object.keys(menus) as Array<keyof typeof menus>).map(
          (pathName: keyof typeof menus) => {
            const { to, IconComponent, disabled } = menus[pathName];
            return (
              <StyledLink
                to={disabled ? '#' : to}
                key={pathName}
                disabled={!!disabled}
              >
                <MenuItem
                  sx={{ padding: '10px 20px' }}
                  selected={pathName === currentPathName}
                  disabled={!!disabled}
                >
                  <StyledIcon>
                    <IconComponent size={16} />
                  </StyledIcon>
                  <ListItemText primary={pathName} />
                </MenuItem>
              </StyledLink>
            );
          }
        )}
      </MenuList>
      <BottomMenuList>
        <UserMenuList />
      </BottomMenuList>
    </Sidebar>
  );
};

export default SideBar;

const MenuList = styled(List)(({ theme }) => ({
  background: theme.custom.bgDark,
  '& .Mui-selected': {
    backgroundColor: `${theme.custom.bg}`,
  },
}));

const Sidebar = styled('div')(({ theme }) => ({
  position: 'relative',
  height: '100%',
  background: theme.custom.bgDark,
  '& .MuiList-root': {
    background: theme.custom.bgDark,
    color: theme.custom.textActive,
    border: 'none',
  },
  zIndex: 1,
}));

const StyledLink = styled(Link)<{ disabled: boolean }>(
  ({ theme, disabled }) => ({
    color: theme.custom.textActive,
    height: '100%',
    cursor: disabled ? 'not-allowed' : 'pointer',
    '&:link': {
      textDecoration: 'none',
    },
  })
);

const StyledIcon = styled('div')(({ theme }) => ({
  color: theme.custom.primary,
  marginRight: 8,
}));

const BottomMenuList = styled('div')`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
`;

const Heading = styled('h1')`
  margin: 16px 16px 0;
  padding: 0;
  font-size: 20px;
  font-weight: 300;
`;

const Version = styled('span')`
  font-size: 14px;
`;

const SubHeading = styled('p')`
  margin: 8px 16px 16px 16px;
  padding: 0;
  font-size: 12px;
  white-space: nowrap;
`;
