import { useRecoilValue } from 'recoil';

import DialogContent from '@mui/material/DialogContent';
import { styled } from '@mui/material/styles';

import ModalityViewer from 'src/components/Viewer/ModalityViewer';
import { contoursByShapeState } from 'src/states/annotation';
import { caseState } from 'src/states/case';

import CommonDialog, { CommonDialogProps } from './CommonDialog';

export interface JobDialogProps extends CommonDialogProps {
  jobId: string;
  caseId: string;
  dbName: string;
  modality: string;
}

export default function ViewJobDialog({
  open,
  onClose = () => {},
  jobId,
  caseId,
  dbName,
  modality,
}: JobDialogProps): JSX.Element {
  const caseSchema = useRecoilValue(
    caseState.caseSchema({
      dbName,
      caseId,
      modality,
    })
  );
  const caseImages = caseSchema?.images;

  const contoursByShape = useRecoilValue(
    contoursByShapeState({ jobId, dbName })
  );

  return (
    <CommonDialog
      onClose={onClose}
      aria-labelledby="view-job-and-annotation"
      open={open}
      title="View Job"
    >
      <DialogContent>
        <ViewerContainer>
          <ModalityViewer
            modalityLabel={modality}
            images={caseImages}
            contoursByShape={contoursByShape}
          />
        </ViewerContainer>
      </DialogContent>
    </CommonDialog>
  );
}

const ViewerContainer = styled('div')`
  width: 500px;
`;
