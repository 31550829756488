import { StrictMode, Suspense } from 'react';

import { ErrorBoundary } from 'react-error-boundary';
import styled from 'styled-components';

import useImageLoaderScripts from 'src/hooks/useImageLoaderScripts';
import useTokenValidator from 'src/hooks/useTokenValidator';

import { CombinedProviders } from './CombinedProvider';
import Routes from './Routes';
import { GlobalErrorFallback } from './components/ErrorFallback/GlobalErrorFallback';

const FlexWrapper = styled.div`
  display: flex;
  height: 100%;
`;

function App(): JSX.Element {
  useTokenValidator();
  useImageLoaderScripts();

  return (
    <StrictMode>
      <CombinedProviders>
        <ErrorBoundary key="Global" FallbackComponent={GlobalErrorFallback}>
          <Suspense fallback={null}>
            <FlexWrapper>
              <Routes />
            </FlexWrapper>
          </Suspense>
        </ErrorBoundary>
      </CombinedProviders>
    </StrictMode>
  );
}

export default App;
