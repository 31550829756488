import { Hosts } from '.';
import productionHosts from './production';

const hosts: Hosts = {
  ...productionHosts,
  CTL_AUTH_SERVER: 'https://auth.ctl.research.stg.lunit.io',
  CTL_DATA_SERVER: 'https://data.ctl.research.stg.lunit.io',
  CTL_IMAGE_SERVER: 'https://image.ctl.research.stg.lunit.io',
};

export default hosts;
