import { atom, selector, useSetRecoilState } from 'recoil';

import {
  DEFAULT_REPORT,
  getReportList,
  GetReportListProps,
} from 'src/api/reports';
import { ReportReadSchema, ReportListSchema } from 'src/types/api/data/report';
import { getLastTimeOfDay, today } from 'src/utils/date';

const reportListQueryRefreshState = atom({
  key: 'reportListQuery/REFRESH',
  default: 0,
});

export function useRefreshReportList(): () => void {
  const setRefreshState = useSetRecoilState(reportListQueryRefreshState);
  return () => {
    setRefreshState(v => v + 1);
  };
}

export const initialFilters: GetReportListProps = {
  page: 1,
  dateFrom: '',
  dateTo: getLastTimeOfDay(today).toISOString(),
  resolvedOnly: undefined,
  includeResolved: true,
  databaseOption: null,
  userOption: {
    value: 'all',
    label: 'All users',
  },
};

export const reportListQueryParamState = atom<GetReportListProps>({
  key: 'reportListQuery/PARAM',
  default: initialFilters,
});

export const reportListQuery = selector<ReportListSchema>({
  key: 'reportList',
  get: async ({ get }) => {
    get(reportListQueryRefreshState);
    const params = get(reportListQueryParamState);
    return getReportList(params);
  },
});

export const selectedReport = atom<ReportReadSchema>({
  key: 'selectedReport',
  default: DEFAULT_REPORT,
});

export const isDetailDrawerOpen = atom({
  key: 'report/isDetailDrawerOpen',
  default: false,
});
