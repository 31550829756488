import { atom, selectorFamily, useSetRecoilState } from 'recoil';

import { getProject, getProjectList } from 'src/api';
import { ProjectListProps } from 'src/api/projects';

const refreshProject = atom({
  key: 'project/refreshProject',
  default: 0,
});

export function useRefreshProject(): () => void {
  const setRefreshState = useSetRecoilState(refreshProject);
  return () => {
    setRefreshState(v => v + 1);
  };
}

type projectProps = { projectId: string; dbName: string };

const project = selectorFamily({
  key: 'project/current',
  get:
    ({ projectId, dbName }: projectProps) =>
    async ({ get }) => {
      get(refreshProject); //subscribe refresh state
      return getProject(projectId, dbName);
    },
});

const refreshProjectList = atom({
  key: 'project/refreshProjectList',
  default: 0,
});

export function useRefreshProjectList(): () => void {
  const setRefreshState = useSetRecoilState(refreshProjectList);
  return () => {
    setRefreshState(v => v + 1);
  };
}

const projectList = selectorFamily({
  key: 'project/projectList',
  get:
    (props: ProjectListProps) =>
    async ({ get }) => {
      get(refreshProjectList); //subscribe refresh state
      return getProjectList(props);
    },
});

export const projectState = Object.freeze({
  project,
  projectList,
});
