import { Suspense } from 'react';

import { ErrorBoundary } from 'react-error-boundary';
import { Route, Switch, useHistory } from 'react-router-dom';

import PrivateRoute from './PrivateRoute';
import { SideBar, Contents } from './components';
import AllScreenSpinner from './components/AllScreenSpinner';
import RouteErrorFallback from './components/ErrorFallback/RouteErrorFallback';
import {
  Login,
  Main,
  ManageProject,
  NotFound,
  Projects,
  CaseSearch,
  AI,
  Users,
  Reports,
  AssetTemplates,
  NewAssetTemplate,
} from './pages';
import ManageAssetTemplate from './pages/ManageAssetTemplate';
import NewProject from './pages/NewProject';
import { useRefreshReportList } from './states/reportList';
import { useRefreshUserList } from './states/user';
import { PathNames } from './types/client/url';
import {
  ASSET_TEMPLATES_PAGE_TITLE,
  MANAGE_ASSET_TEMPLATE_TITLE,
  PROJECTS_PAGE_TITLE,
} from './utils/constants';
import { UrlUtil } from './utils/url';

const Routes = (): JSX.Element => {
  const loginUrl = UrlUtil.getUrl(PathNames.LOGIN);
  const history = useHistory();
  const refreshReportList = useRefreshReportList();
  const refreshUserList = useRefreshUserList();

  return (
    <Switch>
      <Route exact path={loginUrl} component={Login} />
      <PrivateRoute path={UrlUtil.getUrl(PathNames.HOME)}>
        <SideBar />
        <Suspense fallback={<AllScreenSpinner />}>
          <Contents>
            <Switch>
              <Route
                exact
                path={UrlUtil.getUrl(PathNames.HOME)}
                component={Main}
              />
              <Route exact path={UrlUtil.getUrl(PathNames.USERS)}>
                <ErrorBoundary
                  key="UserList"
                  fallbackRender={props => (
                    <RouteErrorFallback pageName="User List" {...props} />
                  )}
                  onReset={() => {
                    refreshUserList();
                  }}
                >
                  <Users />
                </ErrorBoundary>
              </Route>
              <Route path={UrlUtil.getUrl(PathNames.PROJECT)}>
                <ErrorBoundary
                  key="ManageProject"
                  fallbackRender={props => (
                    <RouteErrorFallback
                      pageName="Manage Project"
                      {...props}
                      onReset={history.goBack}
                    />
                  )}
                >
                  <ManageProject />
                </ErrorBoundary>
              </Route>
              <Route exact path={UrlUtil.getUrl(PathNames.PROJECTS)}>
                <ErrorBoundary
                  key="ProjectList"
                  fallbackRender={props => (
                    <RouteErrorFallback
                      pageName={PROJECTS_PAGE_TITLE}
                      {...props}
                    />
                  )}
                >
                  <Projects />
                </ErrorBoundary>
              </Route>
              <Route exact path={UrlUtil.getUrl(PathNames.NEW_PROJECT)}>
                <ErrorBoundary
                  key="NewProject"
                  fallbackRender={props => (
                    <RouteErrorFallback pageName="New Project" {...props} />
                  )}
                >
                  <NewProject />
                </ErrorBoundary>
              </Route>
              <Route exact path={UrlUtil.getUrl(PathNames.CASE_SEARCH)}>
                <ErrorBoundary
                  key="CaseSearch"
                  fallbackRender={props => (
                    <RouteErrorFallback pageName="Search Case" {...props} />
                  )}
                >
                  <CaseSearch />
                </ErrorBoundary>
              </Route>
              <Route
                exact
                path={UrlUtil.getUrl(PathNames.AI_OPERATIONAL)}
                component={AI}
              />
              <Route exact path={UrlUtil.getUrl(PathNames.REPORTS)}>
                <ErrorBoundary
                  key="ReportList"
                  fallbackRender={props => (
                    <RouteErrorFallback pageName="Report List" {...props} />
                  )}
                  onReset={() => {
                    refreshReportList();
                  }}
                >
                  <Reports />
                </ErrorBoundary>
              </Route>

              <Route exact path={UrlUtil.getUrl(PathNames.ASSET_TEMPLATES)}>
                <ErrorBoundary
                  key="AssetTemplatesList"
                  fallbackRender={props => (
                    <RouteErrorFallback
                      pageName={ASSET_TEMPLATES_PAGE_TITLE}
                      {...props}
                    />
                  )}
                >
                  <AssetTemplates />
                </ErrorBoundary>
              </Route>

              <Route exact path={UrlUtil.getUrl(PathNames.NEW_ASSET_TEMPLATE)}>
                <ErrorBoundary
                  key="NewAssetTemplate"
                  fallbackRender={props => (
                    <RouteErrorFallback
                      pageName="New Asset Template"
                      {...props}
                    />
                  )}
                >
                  <NewAssetTemplate />
                </ErrorBoundary>
              </Route>

              <Route
                exact
                path={UrlUtil.getUrl(PathNames.MANAGE_ASSET_TEMPLATE)}
              >
                <ErrorBoundary
                  key="ManageAssetTemplate"
                  fallbackRender={props => (
                    <RouteErrorFallback
                      pageName={MANAGE_ASSET_TEMPLATE_TITLE}
                      {...props}
                    />
                  )}
                >
                  <ManageAssetTemplate />
                </ErrorBoundary>
              </Route>

              <Route component={NotFound} />
            </Switch>
          </Contents>
        </Suspense>
      </PrivateRoute>
    </Switch>
  );
};

export default Routes;
