import styled from 'styled-components';

const Canvas = styled.canvas`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
`;

/**
 * this is used to prevent to download DICOM images as PNG by right clicking
 * it just masks the original image and if somebody tried to download then
 * downloaded image would be just plain image
 *
 *
 * @returns canvas
 */
export function OverlayCanvas(): JSX.Element {
  return <Canvas className="overlay-canvas" />;
}
