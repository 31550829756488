import { useRecoilValueLoadable } from 'recoil';
import styled from 'styled-components';

import DownloadIcon from '@mui/icons-material/Download';
import IconButton from '@mui/material/IconButton';

import { getSignedImageURL } from 'src/api/image';
import { OverlayCanvas } from 'src/components/OverlayCanvas';
import Spinner from 'src/components/Spinner';
import { imageState } from 'src/states/image';
import { Size } from 'src/types/client/ui';

import Viewer, { ViewerProps } from './Viewer';

interface Props extends Omit<ViewerProps, 'image'> {
  imagePath?: string;
}

const LoadableViewer = (props: Props): JSX.Element => {
  const { imagePath, ...viewerProps } = props;
  const { width, height } = viewerProps;

  const imageLoadable = useRecoilValueLoadable(
    imageState.single({ imagePath, width, height })
  );

  const { contents, state } = imageLoadable;
  const image = state === 'hasValue' ? contents : undefined;

  const downloadImage = async () => {
    if (imagePath) {
      const signedURL = await getSignedImageURL(imagePath, width, height);
      const extractedURL = signedURL.replace('wadouri:', '');
      window.open(extractedURL, '_blank');
    }
  };

  return (
    <Container width={width} height={height}>
      {state === 'loading' ? (
        <LoadingImage width={width} height={height}>
          <Spinner size={50} />
        </LoadingImage>
      ) : !image ? (
        <NotFoundImage width={width} height={height}>
          No image has been provided.
        </NotFoundImage>
      ) : (
        <ViewerContainer>
          <OverlayCanvas />
          <Viewer {...viewerProps} image={image} />
        </ViewerContainer>
      )}
      {imagePath && (
        <DownloadButton>
          <IconButton
            aria-label="download-button"
            onClick={downloadImage}
            size="small"
          >
            <DownloadIcon />
          </IconButton>
        </DownloadButton>
      )}
    </Container>
  );
};

const Container = styled.div<Size>`
  width: ${props => props.width || 0}px;
  height: ${props => props.height || 0}px;
  position: relative;
`;

const ViewerContainer = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
`;

const DownloadButton = styled.div`
  position: absolute;
  width: 30px;
  height: 30px;
  bottom: 0;
  right: 0;
  z-index: 2;
`;

const LoadingImage = styled.div<Size>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${props => props.width || 0}px;
  height: ${props => props.height || 0}px;
  position: absolute;
  top: 0;
  background-color: black;
`;

const NotFoundImage = styled.div<Size>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${props => props.width || 0}px;
  height: ${props => props.height || 0}px;
  background-color: black;
`;

export default LoadableViewer;
