import { atom, selector, selectorFamily } from 'recoil';

import { getMyInfo } from 'src/api';
import { User } from 'src/types/client/user';
import { ClientErrorCode, ClientError } from 'src/utils/clientError';
import { getDbOptions } from 'src/utils/user';

export const myInfoState = atom<User>({
  key: 'myInfoState',
  default: selector({
    key: 'user',
    get: async () => {
      const myInfo = await getMyInfo();

      if (!myInfo.isAdmin) {
        throw new ClientError({
          code: ClientErrorCode.UNAUTHORIZED,
          message: 'Only administrator is available to login!',
        });
      }

      return myInfo;
    },
  }),
});

export const myModalityOptionState = selector({
  key: 'myModalityOptionState',
  get: ({ get }) => {
    const user = get(myInfoState);
    return getDbOptions(user);
  },
});

export const myDBNameState = selectorFamily({
  key: 'myDBName',
  get:
    (modalityLabel: string) =>
    ({ get }) => {
      const user = get(myInfoState);
      const dbName =
        user.DBAuthorizationList.find(auth => auth.modality === modalityLabel)
          ?.name || '';

      return dbName;
    },
});
