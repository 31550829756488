import { useRouteMatch } from 'react-router-dom';
import { useRecoilValue, useRecoilValueLoadable } from 'recoil';

import Button from '@mui/material/Button';

import { purgeProject } from 'src/api/projects';
import DropdownMenu from 'src/components/Input/DropdownMenu';
import useAlertSnackbar from 'src/hooks/useAlertSnackbar';
import useConfirmModal from 'src/hooks/useConfirmModal';
import { myDBNameState } from 'src/states/myInfo';
import { projectState, useRefreshProject } from 'src/states/project';
import { userState } from 'src/states/user';
import { SelectOption } from 'src/types/client/ui';

const PurgeProjectButton = (): JSX.Element => {
  const { getConfirmation } = useConfirmModal();
  const { openAlertSnackbar } = useAlertSnackbar();

  const {
    params: { modalityLabel, projectId },
  } = useRouteMatch<{
    modalityLabel: string;
    projectId: string;
  }>();

  const refreshProject = useRefreshProject();

  const dbName = useRecoilValue(myDBNameState(modalityLabel));
  const project = useRecoilValue(projectState.project({ projectId, dbName }));

  const userFilterListLoadable = useRecoilValueLoadable(
    userState.annotatorOptionList({ projectId, dbName })
  );

  const onClickPurgeProjectButton = async () => {
    let userOption: SelectOption | undefined;

    const confirmation = await getConfirmation({
      title: 'Purge Project Confirmation',
      description: `WARNING: This will delete all readings in the current project for the selected user and 
      return all their assigned annotation jobs to the job pool. This is an irreversible operation. 
      Are you sure you want to delete all of the selected user's readings from the project ${project.name}?`,
      children: (
        <DropdownMenu
          label="Select annotator"
          options={
            userFilterListLoadable.state === 'hasValue'
              ? userFilterListLoadable.contents
              : []
          }
          onChange={(event, opt: SelectOption | null) => {
            if (opt) {
              userOption = opt;
            }
          }}
          sx={{ marginTop: 2 }}
        />
      ),
    });

    if (confirmation === true) {
      if (!userOption) {
        openAlertSnackbar({
          severity: 'error',
          description: 'Annotator is not selected',
        });
        return;
      }
      try {
        await purgeProject(projectId, dbName, userOption?.value);

        openAlertSnackbar({
          severity: 'success',
          description: 'Successfully purged the project',
        });

        refreshProject();
      } catch {
        openAlertSnackbar({
          severity: 'error',
          description: 'Failed to purge the project',
        });
      }
    }
  };

  return (
    <Button
      color="warning"
      variant="contained"
      disabled={userFilterListLoadable.state === 'loading'}
      onClick={onClickPurgeProjectButton}
      style={{ width: 200 }}
    >
      Purge project
    </Button>
  );
};

export default PurgeProjectButton;
