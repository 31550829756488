import {
  DatasetListSchema,
  DatasetReadSchema,
} from 'src/types/api/data/dataset';
import { dataApi } from 'src/utils/http';

export const getDataset = async (
  dbName: string,
  datasetId: string
): Promise<DatasetReadSchema> => {
  const { data } = await dataApi.get<DatasetReadSchema>(
    `/datasets/${datasetId}/`,
    {
      params: {
        database_name: dbName,
      },
    }
  );

  return data;
};

export const getDatasets = async (
  dbName: string,
  page?: number,
  pageSize?: number
): Promise<DatasetListSchema> => {
  const { data } = await dataApi.get<DatasetListSchema>(`/datasets/`, {
    params: {
      database_name: dbName,
      page: page || 1,
      page_size: pageSize,
      fields: ['id', 'name', 'createdAt'],
    },
  });

  return data;
};
