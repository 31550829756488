import { ComponentType } from 'react';

import {
  Contour,
  ContourViewer,
  ContourViewerProps,
  CornerstoneRenderData,
  LineViewer,
  Point,
  PointViewer,
} from '@lunit/insight-viewer';
import styled from 'styled-components';

import { ContoursByShape } from 'src/states/annotation';

interface Props {
  width: number;
  height: number;
  view: string;
  contoursByShape: ContoursByShape;
  cornerstoneRenderData: CornerstoneRenderData | null;
}

const AnnotationDrawer = ({
  width,
  height,
  view,
  contoursByShape,
  cornerstoneRenderData,
}: Props): JSX.Element | null => {
  const imageWidth = cornerstoneRenderData?.image?.width || 0;
  const imageHeight = cornerstoneRenderData?.image?.height || 0;
  const { polygon, line, point } = contoursByShape;

  if (!cornerstoneRenderData) {
    return null;
  }

  return (
    <>
      {polygon && (
        <StyledContourViewer
          width={width}
          height={height}
          contours={
            polygon[view]?.map(contour => {
              return {
                ...contour,
                polygon: contour.polygon.map(([x, y]) => [
                  x * imageWidth,
                  y * imageHeight,
                ]),
              } as Contour;
            }) || []
          }
          focusedContour={null}
          cornerstoneRenderData={cornerstoneRenderData}
        />
      )}
      {line && (
        <LineViewer
          width={width}
          height={height}
          contours={
            line[view]?.map(a => {
              return {
                ...a,
                polygon: a.polygon.map(
                  ([x, y]) => [x * imageWidth, y * imageHeight] as Point
                ),
              };
            }) || []
          }
          focusedContour={null}
          cornerstoneRenderData={cornerstoneRenderData}
        />
      )}
      {point && (
        <PointViewer
          width={width}
          height={height}
          contours={
            point[view]?.map(a => {
              return {
                ...a,
                polygon: a.polygon.map(
                  ([x, y]) => [x * imageWidth, y * imageHeight] as Point
                ),
              };
            }) || []
          }
          focusedContour={null}
          cornerstoneRenderData={cornerstoneRenderData}
        />
      )}
    </>
  );
};

export default AnnotationDrawer;

const StyledContourViewer = styled<ComponentType<ContourViewerProps<Contour>>>(
  ContourViewer
)`
  polygon {
    stroke-width: 4px;
  }
`;
