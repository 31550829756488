import { atom, selectorFamily, useSetRecoilState } from 'recoil';

import { getAssetTemplate, getAssetTemplates } from 'src/api/assetTemplates';

const refreshAssetTemplatesList = atom({
  key: 'assetTemplates/refreshAssetTemplatesList',
  default: 0,
});

export function useRefreshAssetTemplatesList(): () => void {
  const setRefreshState = useSetRecoilState(refreshAssetTemplatesList);
  return () => {
    setRefreshState(v => v + 1);
  };
}

const list = selectorFamily({
  key: 'assetTemplate/list',
  get:
    (dbName: string) =>
    ({ get }) => {
      get(refreshAssetTemplatesList);
      return getAssetTemplates(dbName);
    },
});

type AssetTemplateProps = { assetTemplateId: string; dbName: string };

const current = selectorFamily({
  key: 'assetTemplate/current',
  get:
    ({ assetTemplateId, dbName }: AssetTemplateProps) =>
    () =>
      getAssetTemplate(assetTemplateId, dbName),
});

export const assetTemplateState = Object.freeze({
  list,
  current,
});
