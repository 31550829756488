import { useEffect, useState, useMemo } from 'react';

import {
  matchPath,
  Redirect,
  Route,
  Switch,
  useHistory,
  useRouteMatch,
} from 'react-router-dom';
import { Tab, TabList, TabPanel } from 'react-tabs';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

import { Flexbox, Vertical } from 'src/components/Alignments';
import BlockQuote from 'src/components/BlockQuote';
import { SectionTitleWithBreadcrumbs } from 'src/components/Section';
import StyledTabs from 'src/components/Tabs';
import { myDBNameState } from 'src/states/myInfo';
import { projectState } from 'src/states/project';
import { projectStatsParamsState } from 'src/states/stats';
import { JobsStatsState } from 'src/types/client/stats';
import { PathNames } from 'src/types/client/url';
import { PROJECTS_PAGE_TITLE } from 'src/utils/constants';
import { UrlUtil } from 'src/utils/url';

import AnnotatorsTab from './AnnotatorsTab';
import ConfirmProjectButton from './ConfirmProjectButton';
import JobsTab from './JobsTab';
import PurgeProjectButton from './PurgeProjectButton';
import ReviewersTab from './ReviewersTab';
import StatsTab from './StatsTab';

const MENUS = ['Annotators', 'Reviewers', 'Jobs', 'Stats'];

// TODO: Need error code 400 for empty dataset?
export default function ManageProject(): JSX.Element {
  const {
    params: { modalityLabel, projectId },
  } = useRouteMatch<{
    modalityLabel: string;
    projectId: string;
  }>();

  const history = useHistory();

  const [currentMenuIndex, setCurrentMenuIndex] = useState<number>(0);
  const [jobStatsState, setJobStatsState] =
    useState<JobsStatsState>('hasOngoingJobs');
  const dbName = useRecoilValue(myDBNameState(modalityLabel));
  const project = useRecoilValue(projectState.project({ projectId, dbName }));
  const setParams = useSetRecoilState(projectStatsParamsState);

  const getTabUrl = (menuIndex: number) => {
    const tabName = MENUS[menuIndex]?.toLowerCase() || 'annotators';
    return UrlUtil.getUrl(PathNames.PROJECT_TAB, {
      modalityLabel,
      projectId,
      tabName,
    });
  };

  const pushHistoryTab = (idx: number) => {
    history.push(getTabUrl(idx));
  };

  useEffect(() => {
    const { total, completedStage, createdStage } = project.jobStats.annotation;
    if (createdStage === 0) setJobStatsState('noJobLeft');
    else setJobStatsState('hasOngoingJobs');
    if (total > 0 && total === completedStage) setJobStatsState('allJobsDone');
  }, [project.jobStats.annotation]);

  useEffect(() => {
    setParams({
      projectId,
      dbName,
    });
  }, [dbName, projectId, setParams]);

  // Set current tab when location change
  useEffect(() => {
    const { tabName } = matchPath<{ tabName: string }>(
      history.location.pathname,
      {
        path: UrlUtil.getUrl(PathNames.PROJECT_TAB),
      }
    )?.params || { tabName: '' };
    const index = MENUS.findIndex(menu => menu.toLocaleLowerCase() === tabName);
    setCurrentMenuIndex(index);
  }, [history.location.pathname]);

  const projectInfo = useMemo(() => {
    return [
      {
        info: 'Annotation progress (done / total)',
        value: `${project.jobStats.annotation.completedStage} / ${project.jobStats.annotation.total}`,
        textColor: jobStatsState === 'allJobsDone' ? '#06e20d' : '',
      },
      {
        info: 'Assignable annotation jobs',
        value: project.jobStats.annotation.createdStage,
        textColor: jobStatsState === 'noJobLeft' ? '#e80303' : '',
      },
      {
        info: 'Completed corroboration jobs',
        value: project.jobStats.corroboration.completedStage,
      },
      {
        info: 'Corroboration probability',
        value: project.claim.assignRule?.corroboration?.probability || 0.0,
      },
      {
        info: 'Max number of corroboration jobs',
        value: project.claim.assignRule?.corroboration?.max || 0,
      },
    ];
  }, [jobStatsState, project]);

  const goBack = () => {
    history.push(`${UrlUtil.getUrl(PathNames.PROJECTS, { modalityLabel })}`);
  };

  return (
    <Vertical>
      <SectionTitleWithBreadcrumbs>
        <Link
          underline="hover"
          color="inherit"
          variant="h5"
          onClick={goBack}
          sx={{ cursor: 'pointer' }}
        >
          {PROJECTS_PAGE_TITLE}
        </Link>
        <Typography color="text.primary" variant="h5">
          {project.name || ''}
        </Typography>
      </SectionTitleWithBreadcrumbs>

      <BlockQuote style={{ marginTop: 0 }}>
        <ProjectInfoHeader>
          <Grid container>
            <Grid item md={12} lg={6}>
              <InfoTable>
                <tbody>
                  {projectInfo.slice(0, 3).map(infoRow => (
                    <InfoTableRow key={infoRow.info}>
                      <InfoTableCell>{infoRow.info}</InfoTableCell>
                      <InfoTableCell
                        style={{ color: infoRow.textColor, width: '100%' }}
                      >
                        <strong>{infoRow.value}</strong>
                      </InfoTableCell>
                    </InfoTableRow>
                  ))}
                </tbody>
              </InfoTable>
            </Grid>
            <Grid item md={12} lg={6}>
              <InfoTable>
                <tbody>
                  {projectInfo.slice(3).map(infoRow => (
                    <InfoTableRow key={infoRow.info}>
                      <InfoTableCell>{infoRow.info}</InfoTableCell>
                      <InfoTableCell
                        style={{ color: infoRow.textColor, width: '100%' }}
                      >
                        <strong>{infoRow.value}</strong>
                      </InfoTableCell>
                    </InfoTableRow>
                  ))}
                </tbody>
              </InfoTable>
            </Grid>
          </Grid>
        </ProjectInfoHeader>
      </BlockQuote>

      {(project.confirmed && (
        <ConfirmAlert severity="warning" sx={{ marginBottom: 0 }}>
          This project was already confirmed! Further updates not allowed.
        </ConfirmAlert>
      )) || (
        <Flexbox $align="center" $justify="flex-end" style={{ gap: '1rem' }}>
          <Typography>
            {project.claim.assignRule?.corroboration?.finishWhenAnnotationDone
              ? 'This project will be finished when annotation jobs are done.'
              : `This project has only corroboration jobs.`}
          </Typography>

          <ConfirmProjectButton />
          <PurgeProjectButton />
        </Flexbox>
      )}

      <StyledTabs
        selectedIndex={currentMenuIndex}
        onSelect={(idx: number) => pushHistoryTab(idx)}
      >
        <TabList>
          {MENUS.map(menu => (
            <Tab key={menu}>{menu}</Tab>
          ))}
        </TabList>

        <Switch>
          <Route exact path={getTabUrl(0)}>
            <TabPanel>
              <AnnotatorsTab
                projectId={projectId}
                dbName={dbName}
                modality={modalityLabel}
                jobStatsState={jobStatsState}
              />
            </TabPanel>
          </Route>
          <Route exact path={getTabUrl(1)}>
            <TabPanel>
              <ReviewersTab
                projectId={projectId}
                dbName={dbName}
                modality={modalityLabel}
              />
            </TabPanel>
          </Route>
          <Route exact path={getTabUrl(2)}>
            <TabPanel>
              <JobsTab
                projectId={projectId}
                dbName={dbName}
                modality={modalityLabel}
              />
            </TabPanel>
          </Route>
          <Route exact path={getTabUrl(3)}>
            <TabPanel>
              <StatsTab projectId={projectId} dbName={dbName} />
            </TabPanel>
          </Route>
          <Route
            exact
            path={UrlUtil.getUrl(PathNames.PROJECT, {
              modalityLabel,
              projectId,
            })}
          >
            <Redirect to={getTabUrl(0)} />
          </Route>
        </Switch>
      </StyledTabs>
    </Vertical>
  );
}

const ProjectInfoHeader = styled(Flexbox)(({ theme }) => ({
  color: theme.custom.textActive,
}));

const InfoTable = styled('table')`
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  margin: 0 -0.5rem;
`;
const InfoTableRow = styled('tr')``;
const InfoTableCell = styled('td')`
  white-space: nowrap;
  padding: 0.125rem 0.5rem;
`;

const ConfirmAlert = styled(Alert)`
  width: 100%;
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 20px;
`;
