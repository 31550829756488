import { atom, selector } from 'recoil';

import { getProjectJobStats } from 'src/api';
import { DEFAULT_STAT } from 'src/api/projects';
import {
  PieChartDataProps,
  GetProjectStatProps,
  JobStats,
} from 'src/types/client/stats';

export const projectStatsParamsState = atom<GetProjectStatProps>({
  key: 'projectStats/PARAMS',
  default: {
    projectId: '',
    dbName: '',
  },
});

export interface ProjectTotalStats {
  unfinished: number; // created + assigned + queued
  reported: number;
  completed: number;
}

export const projectStatsState = atom<JobStats>({
  key: 'projectStatsState',
  default: selector({
    key: 'projectStats',
    get: async ({ get }) => {
      const params = get(projectStatsParamsState);

      const { projectId, dbName } = params;
      if (!projectId || !dbName) {
        return {
          total: DEFAULT_STAT,
          annotation: DEFAULT_STAT,
          corroboration: DEFAULT_STAT,
        };
      }

      return getProjectJobStats(params);
    },
  }),
});

interface ProjectTotalStatsReturn {
  stats: PieChartDataProps[];
  total: number;
}

export const projectTotalStats = selector<ProjectTotalStatsReturn>({
  key: 'projectTotalStats',
  get: ({ get }) => {
    const projectStats = get(projectStatsState);
    const { createdStage, assignedStage, queuedStage } = projectStats.total;

    const stats: ProjectTotalStats = {
      unfinished: createdStage + assignedStage + queuedStage,
      reported: projectStats.total.reportedStage,
      completed: projectStats.total.completedStage,
    };

    const formattedStats: PieChartDataProps[] = (
      Object.keys(stats) as Array<keyof ProjectTotalStats>
    ).map(key => ({
      id: key,
      label: key,
      value: stats[key],
    }));

    return { stats: formattedStats, total: projectStats.total?.total };
  },
});
