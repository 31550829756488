import { Contour, Point } from '@lunit/insight-viewer';
import {
  ClaimAnnotationResponse,
  DecisionSelectorLabel,
} from '@lunit-io/radiology-data-interface';
import groupBy from 'lodash-es/groupBy';
import mapValues from 'lodash-es/mapValues';
import { selectorFamily } from 'recoil';

import { getAnnotation, GetAnnotationProps } from 'src/api/annotation';

export const annotationListQuery = selectorFamily<
  ClaimAnnotationResponse,
  GetAnnotationProps
>({
  key: 'annotationListQuery',
  get: params => async () => {
    const DEFAULT_RESPONSE = {
      findings: [],
      labels: [],
    };
    try {
      const annotation = await getAnnotation(params);
      return annotation || DEFAULT_RESPONSE;
    } catch (e) {
      return DEFAULT_RESPONSE;
    }
  },
});

type Shape = 'point' | 'line' | 'polygon';

export type ContoursByShape = {
  [shape in Shape]: { [k: string]: Contour[] };
};

export const contoursByShapeState = selectorFamily<
  ContoursByShape,
  GetAnnotationProps
>({
  key: 'contoursByShapeState',
  get:
    params =>
    async ({ get }) => {
      const { findings, labels } = get(annotationListQuery(params));

      // { polygon: Finding[], line: Finding[], ...}
      const findingsByShape = groupBy(findings, 'shape');

      return mapValues(findingsByShape, finding => {
        const findingsByImage = groupBy(finding, 'image');
        return mapValues(findingsByImage, findings =>
          findings.map<Contour>((finding, index) => {
            const { value } = labels.find(
              label => label.index === index
            ) as unknown as DecisionSelectorLabel;

            const label = Object.keys(value).find(key => value[key]);
            const invertedPolygon = (finding.points as Point[]).map(
              ([y, x]) => [x, y] as Point
            );

            return {
              id: index,
              label,
              polygon: invertedPolygon,
            };
          })
        );
      }) as ContoursByShape;
    },
});
