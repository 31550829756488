import {
  useEffect,
  useState,
  FormEvent,
  SyntheticEvent,
  Suspense,
} from 'react';

import { useRecoilValue, useRecoilValueLoadable } from 'recoil';
import styled from 'styled-components';

import Search from '@mui/icons-material/Search';
import LoadingButton from '@mui/lab/LoadingButton';

import ToggleButtons from 'src/components/Button/ToggleButton';
import FilterPanel from 'src/components/FilterPanel';
import DropdownMenu from 'src/components/Input/DropdownMenu';
import { datasetState } from 'src/states/dataset';
import { myModalityOptionState } from 'src/states/myInfo';
import { CaseSearchQueryConfig, ReadingType } from 'src/types/api/data/case';
import { SelectOption } from 'src/types/client/ui';
import { Tuple } from 'src/utils/typeHelpers';

import QueryBuilders from './QueryBuilders';

interface CaseSearchQueryFormProps {
  onSubmit: (value: CaseSearchQueryConfig) => void;
  onChangeModality?: (opt: SelectOption) => void;
  isModalityChangeable?: boolean;
  isSearching?: boolean;
  modality?: string;
}

const readingTypes: Tuple<SelectOption<ReadingType | 'none'>, 4> = [
  { label: 'None', value: 'none' },
  { label: 'Clinical Report', value: 'clinicalReport' },
  { label: 'AI Prediction', value: 'prediction' },
  { label: 'Labeled', value: 'labeled' },
];

export default function CaseSearchQueryForm({
  onSubmit,
  isSearching = false,
  modality,
  isModalityChangeable,
  onChangeModality = () => {},
}: CaseSearchQueryFormProps): JSX.Element {
  const myModalityOptionList = useRecoilValue(myModalityOptionState);

  const myModalityOptions = !isModalityChangeable
    ? myModalityOptionList.filter(option => option.label === modality)
    : myModalityOptionList;

  const [selectedModality, setSelectedModality] = useState<SelectOption>();
  const [selectedDataset, setSelectedDataset] = useState<SelectOption>();

  const datasetOptsLoadable = useRecoilValueLoadable(
    datasetState.allDatasetOptionList({
      dbName: selectedModality?.value || '',
    })
  );
  const { state, contents } = datasetOptsLoadable;
  const datasetOpts = state === 'hasValue' ? contents : [];

  const [caseFilter, setCaseFilter] = useState<string>();
  const [readingFilter, setReadingFilter] = useState<string>();

  const [readingType, setReadingType] = useState<
    SelectOption<ReadingType | 'none'>
  >(readingTypes[0]);

  const handleChangeModality = (opt: SelectOption) => {
    setSelectedModality(opt);
    onChangeModality(opt);

    if (!!opt) {
      setSelectedDataset(undefined);
    }
  };

  const handleChangeDataset = (
    event: SyntheticEvent,
    opt: SelectOption | null
  ) => {
    setSelectedDataset(opt || undefined);
  };

  const handleClickReadingType = (
    option: SelectOption<ReadingType | 'none'>
  ) => {
    setReadingType(option);
  };

  // TODO: validation change if reduced
  // Validate required options are set
  const isValid = selectedModality && selectedModality.value !== '';

  // Submit the query
  const handleSubmitQuery = (event: FormEvent) => {
    event.preventDefault();

    if (!isValid) return;

    const payload: CaseSearchQueryConfig = {
      modality: selectedModality?.label || '',
      dbName: selectedModality?.value,
      datasetName: selectedDataset?.label,
      caseFilter,
      readingFilter,
      readyForJobOnly: true,
    };

    if (readingType.value === 'none') {
      payload.allowEmptyReading = true;
    } else {
      payload.readingType = readingType.value;
    }

    onSubmit(payload);
  };

  useEffect(() => {
    setSelectedModality(() => {
      if (modality) {
        return myModalityOptions.find(opt => opt.label === modality);
      } else {
        return myModalityOptions[0];
      }
    });
  }, [modality, myModalityOptions]);

  return (
    <Form>
      <FilterPanel>
        <ToggleButtons
          options={myModalityOptions}
          value={selectedModality || null}
          onChangeLabel={handleChangeModality}
        />
        <DropdownMenu
          options={datasetOpts}
          label="Select a dataset"
          onChange={handleChangeDataset}
          value={selectedDataset}
          disabled={state === 'loading'}
          loading={state === 'loading'}
          sx={{ minWidth: '20rem' }}
        />
        <ToggleButtons
          options={readingTypes}
          value={readingType}
          onChangeLabel={handleClickReadingType}
        />
      </FilterPanel>

      <Suspense>
        <QueryBuilders
          selectedModality={selectedModality}
          setCaseFilter={setCaseFilter}
          setReadingFilter={setReadingFilter}
        />
      </Suspense>

      <LoadingButton
        onClick={handleSubmitQuery}
        variant="contained"
        color="primary"
        loading={isSearching}
        loadingPosition="center"
        disabled={!isValid}
        startIcon={<Search />}
      >
        Search
      </LoadingButton>
    </Form>
  );
}

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  width: auto;
`;
