import { ReactNode, ReactElement } from 'react';

import { HelpCircle } from 'react-feather';
import styled from 'styled-components';

import Breadcrumbs from '@mui/material/Breadcrumbs';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { styled as muiStyled } from '@mui/material/styles';
const PREFIX = 'Section';

const classes = {
  tooltip: `${PREFIX}-tooltip`,
};

const StyledSectionTitle = muiStyled(SectionTitle)(() => ({
  [`& .${classes.tooltip}`]: {
    fontSize: '12px',
  },
}));

const ErrorTextCSS = styled.h6`
  margin: 0 0 10px 0;
  padding: 10px 0 0 12px;
  font-weight: 300;
  font-size: 15px;
  color: red;
  font-weight: light;
`;

const StyledTooltip = styled(Tooltip)`
  margin-left: 10px;
  transform: translateY(2px);
  opacity: 0.5;
  cursor: pointer;
`;

export function ErrorText({ children }: { children: ReactNode }): JSX.Element {
  return <ErrorTextCSS>{children}</ErrorTextCSS>;
}

export function SectionTitle({
  children,
}: {
  children: ReactNode;
}): JSX.Element {
  return (
    <Typography
      color="text.primary"
      variant="h5"
      component="h2"
      sx={{ marginBottom: '2rem' }}
    >
      {children}
    </Typography>
  );
}

export function SectionTitleWithTooltip({
  children,
  tooltip,
}: {
  children: ReactNode;
  tooltip: ReactElement;
}): JSX.Element {
  return (
    <StyledSectionTitle>
      {children}
      <StyledTooltip classes={classes} title={tooltip} placement="right">
        <HelpCircle size={18} />
      </StyledTooltip>
    </StyledSectionTitle>
  );
}

export function SectionTitleWithBreadcrumbs({
  children,
}: {
  children: ReactNode;
}): JSX.Element {
  return (
    <SectionTitle>
      <Breadcrumbs aria-label="breadcrumb">{children}</Breadcrumbs>
    </SectionTitle>
  );
}
