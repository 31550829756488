import { useCallback, useMemo } from 'react';

import { useRecoilValueLoadable } from 'recoil';

import { assetTemplateState } from 'src/states/assetTemplates';
import { AssetTemplateReadSchema } from 'src/types/api/data/assetTemplate';

interface Return {
  filteredAssetTemplates: AssetTemplateReadSchema[];
  isLoading: boolean;
}

function useFilteredAssetTemplates(
  filterBy: string,
  modalityValue: string
): Return {
  const assetTemplateLoadable = useRecoilValueLoadable(
    assetTemplateState.list(modalityValue)
  );
  const { state, contents } = assetTemplateLoadable;

  const assetTemplates = useMemo(
    () => (state === 'hasValue' ? contents : []),
    [contents, state]
  );
  const isLoading = state === 'loading';

  const filterRegExp = useMemo(() => new RegExp(filterBy, 'i'), [filterBy]);

  const filterAssetTemplate = useCallback(
    (assetTemplate: AssetTemplateReadSchema): boolean => {
      const { name, group, form } = assetTemplate;
      const filterableFields = [name, group, form];
      return filterableFields.some(field => filterRegExp.test(field));
    },
    [filterRegExp]
  );

  const filteredAssetTemplates = useMemo(
    () => assetTemplates.filter(filterAssetTemplate),
    [assetTemplates, filterAssetTemplate]
  );

  return {
    filteredAssetTemplates,
    isLoading,
  };
}

export default useFilteredAssetTemplates;
