import styled, { keyframes } from 'styled-components';

const dash = keyframes`
0% {
  stroke-dasharray: 1,240%;
  stroke-dashoffset: 0;
  stroke-linecap: round;
}
50% {
  stroke-dasharray: 200%,240%;
  stroke-dashoffset: -62%;
  stroke-linecap: round;
}
100% {
  stroke-dasharray: 200%,240%;
  stroke-dashoffset: -220%;
  stroke-linecap: round;
}
`;
const rotate = keyframes`
from {transform: rotate(0deg); }
to {transform: rotate(360deg); }
`;

interface SpinnerProps {
  size: number;
  className?: string;
}

const Spinner = ({ className, size = 20 }: SpinnerProps) => (
  <svg
    className={className}
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
  >
    <circle cx={size / 2} cy={size / 2} r={size * 0.375} />
  </svg>
);

const StyledSpinner = styled(Spinner)`
  display: inline-block;
  fill: transparent;
  stroke: white;
  stroke-width: ${props => (props.size ? props.size * 0.125 : 20 * 0.125)}px;
  animation: ${dash} 2s ease infinite, ${rotate} 2s linear infinite;
`;

export default StyledSpinner;
