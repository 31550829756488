import { useMemo, useState } from 'react';

import { ClaimAssetValidationSchema } from '@lunit-io/radiology-data-interface';
import { useRecoilState, useRecoilValue } from 'recoil';

import { newProjectState } from 'src/states/newProject';
import { ASSET_VALIDATION_ITEM_TYPE } from 'src/types/client/asset';

import AssetValidationItem from './AssetValidationItem';
import UpdateAssetValidationDialog from './UpdateAssetValidationDialog';

const ConfiguredList = (): JSX.Element => {
  const [claim, setClaim] = useRecoilState(newProjectState.claim);
  const isValidAssetValidations = useRecoilValue(
    newProjectState.isValidAssetValidations
  );

  const assetValidations = useMemo(
    () => claim.assetValidations || [],
    [claim.assetValidations]
  );

  const [updatingIndex, setUpdatingIndex] = useState<number>();

  const handleClickButton = (index: number) => {
    setUpdatingIndex(index);
  };

  const handleClose = () => {
    setUpdatingIndex(undefined);
  };

  const handleConfirm = (newItem: ClaimAssetValidationSchema) => {
    setClaim(prev => ({
      ...prev,
      assetValidations: (prev.assetValidations || []).map((item, index) => {
        if (index === updatingIndex) {
          return newItem;
        }
        return item;
      }),
    }));
  };

  const handleDelete = (targetIndex: number) => {
    setClaim(prev => ({
      ...prev,
      assetValidations: (prev.assetValidations || []).filter(
        (item, index) => index !== targetIndex
      ),
    }));
  };

  return (
    <>
      {(assetValidations || []).map((item, index) => (
        <AssetValidationItem
          key={index}
          type={ASSET_VALIDATION_ITEM_TYPE.CONFIGURED}
          item={item}
          index={index}
          onClickButton={handleClickButton}
          onDelete={handleDelete}
          isValid={!isValidAssetValidations.invalidIndexes.includes(index)}
        />
      ))}

      <UpdateAssetValidationDialog
        item={
          updatingIndex === undefined
            ? undefined
            : assetValidations[updatingIndex]
        }
        onClose={handleClose}
        onConfirm={handleConfirm}
      />
    </>
  );
};

export default ConfiguredList;
