import { ClaimAssetIdentifierSchema } from '@lunit-io/radiology-data-interface';

import ValidationButton from './ValidationButton';

interface Props {
  item: ClaimAssetIdentifierSchema;
  onClick?: () => void;
}

const ValidationIfSet = ({ item, onClick }: Props): JSX.Element => {
  return (
    <div>
      If the{' '}
      <ValidationButton
        size="small"
        variant="outlined"
        disabled={!onClick}
        onClick={onClick}
      >
        {item.name || 'asset'}
      </ValidationButton>
      's{' '}
      <ValidationButton
        size="small"
        variant="outlined"
        disabled={!onClick}
        onClick={onClick}
      >
        {item.selectedValues?.join(', ') || 'value'}
      </ValidationButton>{' '}
      {item.selectedValues && item.selectedValues.length >= 2 ? 'are' : 'is'}{' '}
      selected,
    </div>
  );
};

export default ValidationIfSet;
