import { useRef } from 'react';

import {
  CornerstoneImage,
  CornerstoneViewer,
  InsightViewerContainer,
  useInsightViewerSync,
  useViewerInteractions,
} from '@lunit/insight-viewer';
import styled from 'styled-components';

import { ContoursByShape } from 'src/states/annotation';
import { Size } from 'src/types/client/ui';

import AnnotationDrawer from './AnnotationDrawer';

export interface ViewerProps extends Size {
  image: CornerstoneImage;
  view: string;
  contoursByShape?: ContoursByShape;
}

const Viewer = ({
  image,
  width,
  height,
  view,
  contoursByShape,
}: ViewerProps): JSX.Element => {
  const elemRef = useRef(null);
  const { cornerstoneRenderData, updateCornerstoneRenderData } =
    useInsightViewerSync();
  const interactions = useViewerInteractions(['zoom', 'pan'], {
    element: elemRef.current,
  });

  return (
    <StyledInsightViewerContainer ref={elemRef} width={width} height={height}>
      <CornerstoneViewer
        image={image}
        width={width}
        height={height}
        flip={false}
        invert={false}
        resetTime={0}
        interactions={interactions}
        updateCornerstoneRenderData={updateCornerstoneRenderData}
      />
      {contoursByShape && (
        <AnnotationDrawer
          width={width}
          height={height}
          view={view}
          contoursByShape={contoursByShape}
          cornerstoneRenderData={cornerstoneRenderData}
        />
      )}
    </StyledInsightViewerContainer>
  );
};

const StyledInsightViewerContainer = styled(InsightViewerContainer)`
  background-color: transparent;
`;

export default Viewer;
