import { ReactNode } from 'react';

import { Redirect, Route, RouteProps } from 'react-router-dom';

import { PathNames } from './types/client/url';
import cookieHelper from './utils/cookieHelper';
import { UrlUtil } from './utils/url';

interface Props extends RouteProps {
  children: ReactNode;
}

const PrivateRoute = ({ children, ...rest }: Props): JSX.Element => (
  <Route
    {...rest}
    render={() => {
      if (!cookieHelper.hasAccessToken()) {
        return <Redirect to={UrlUtil.getUrl(PathNames.LOGIN)} />;
      }

      return children;
    }}
  />
);

export default PrivateRoute;
