import { useMemo, useState, ChangeEvent, Suspense } from 'react';

import { CellProps } from 'react-table';
import { useRecoilValue, useRecoilValueLoadable } from 'recoil';

import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';

import { Vertical } from 'src/components/Alignments';
import FilterPanel from 'src/components/FilterPanel';
import AddCPCCasesDialog from 'src/components/Modal/AddCPCCasesDialog';
import AddCaseDialog from 'src/components/Modal/AddCaseDialog';
import ViewJobDialog, {
  JobDialogProps,
} from 'src/components/Modal/ViewJobDialog';
import Spinner from 'src/components/Spinner';
import Table from 'src/components/Table';
import useDeferredState from 'src/hooks/useDeferredState';
import { jobState, useRefreshJobList } from 'src/states/job';
import {
  projectState,
  useRefreshProject,
  useRefreshProjectList,
} from 'src/states/project';
import { userState } from 'src/states/user';
import { JobReadSchema, JobState } from 'src/types/api/data/job';
import { DEFAULT_PAGE_SIZE } from 'src/utils/constants';
import { getEllipsis } from 'src/utils/string';

import AnnotatorFilter from './AnnotatorFilter';
import JobStateFilter, {
  JobStateOption,
  DEFAULT_JOB_STATE_OPTION,
} from './JobStateFilter';
import JobTypeFilter, {
  DEFAULT_JOB_TYPE_OPTION,
  JobTypeOption,
} from './JobTypeFilter';

interface JobsProps {
  projectId: string;
  modality: string;
  dbName: string;
  annotator?: string;
}

const DEFAULT_ANNOTATOR_OPTION = {
  value: 'all',
  label: 'All users',
};

const isViewJobDialogAvailable = ({
  jobId,
  caseId,
  modality,
}: Pick<JobDialogProps, 'jobId' | 'caseId' | 'modality'>): boolean => {
  return Boolean(jobId && caseId && ['MMG', 'CXR'].includes(modality));
};

export default function Jobs({
  projectId,
  modality,
  dbName,
}: JobsProps): JSX.Element {
  const userOption = useRecoilValue(jobState.jobUserOption);
  const project = useRecoilValue(projectState.project({ projectId, dbName }));
  const refreshProjectList = useRefreshProjectList();
  const refreshProject = useRefreshProject();
  const refreshJobList = useRefreshJobList();

  const [isDialogVisible, setIsDialogVisible] = useDeferredState(false);
  const [page, setPage] = useState<number>(1);
  const [jobTypeOption, setJobTypeOption] = useState<JobTypeOption>(
    DEFAULT_JOB_TYPE_OPTION
  );
  const [stateOption, setStateOption] = useState<JobStateOption>(
    DEFAULT_JOB_STATE_OPTION
  );
  const [jobDialogState, setJobDialogState] = useState<JobDialogProps>({
    open: false,
    jobId: '',
    caseId: '',
    dbName,
    modality,
  });

  const { contents, state: jobListState } = useRecoilValueLoadable(
    jobState.jobList({
      projectId,
      page,
      pageSize: DEFAULT_PAGE_SIZE,
      dbName,
      states: stateOption.value === 'all' ? [] : [stateOption.value],
      annotatorId: userOption.value === 'all' ? undefined : userOption.value,
      type: jobTypeOption.value === 'all' ? undefined : jobTypeOption.value,
    })
  );

  const { jobs, countTotalPages } =
    jobListState === 'hasValue' ? contents : { jobs: [], countTotalPages: 1 };

  const annotatorsOptionsLoadable = useRecoilValueLoadable(
    userState.annotatorOptionList({ projectId, dbName })
  );
  const isAnnotatorLoading = annotatorsOptionsLoadable.state === 'loading';
  const annotatorOptions = useMemo(
    () =>
      annotatorsOptionsLoadable.state === 'hasValue'
        ? [DEFAULT_ANNOTATOR_OPTION, ...annotatorsOptionsLoadable.contents]
        : [DEFAULT_ANNOTATOR_OPTION],
    [annotatorsOptionsLoadable.contents, annotatorsOptionsLoadable.state]
  );

  const handleClickAddCases = () => {
    setIsDialogVisible(true);
  };

  const handleCloseAddCaseDialog = () => {
    setIsDialogVisible(false);
  };

  const handleUpdateAddCase = () => {
    setIsDialogVisible(false);
    refreshProject();
    refreshJobList();
    refreshProjectList();
  };

  const handleChangePage = (event: ChangeEvent<unknown>, page: number) =>
    setPage(page);

  const handleViewJobClick =
    (
      params: Pick<JobDialogProps, 'jobId' | 'caseId' | 'dbName' | 'modality'>
    ) =>
    () => {
      setJobDialogState({ open: true, ...params });
    };

  const handleViewJobDialogClose = () => {
    setJobDialogState(prev => ({ ...prev, open: false }));
  };

  const initPage = () => {
    setPage(1);
  };

  const jobsColumnHeader = useMemo(() => {
    return [
      {
        Header: 'Job List',
        columns: [
          {
            Header: 'ID',
            accessor: 'id',
            style: { width: '35%' },
            Cell: ({ value }: { value: string }) => getEllipsis(value).string,
          },
          {
            Header: 'Job Type',
            accessor: 'jobType',
            Cell: (cell: CellProps<JobReadSchema>) => {
              const { row } = cell;
              const { type } = row.original;
              return type;
            },
          },
          {
            Header: 'Job State',
            id: 'jobState',
            accessor: ({
              reported,
              completed,
              createdAt,
              assignedAt,
              queuedAt,
            }: JobReadSchema): JobState | '-' =>
              reported
                ? 'reported'
                : completed
                ? 'completed'
                : !!queuedAt
                ? 'queued'
                : !!assignedAt
                ? 'assigned'
                : !!createdAt
                ? 'created'
                : '-',
          },
          {
            Header: 'Annotator',
            accessor: 'assignedTo',
            Cell: (cell: CellProps<JobReadSchema>) => {
              if (isAnnotatorLoading) return <Spinner size={26} />;
              const { row } = cell;
              const { assignedTo } = row.original;
              const user = annotatorOptions.find(
                opt => opt.value === assignedTo
              );
              return user?.label || 'None';
            },
          },
          {
            Header: 'View Job',
            accessor: 'viewJob',
            style: { width: '10%' },
            Cell: (cell: CellProps<JobReadSchema>) => {
              const { row } = cell;
              const { id: jobId, case: caseId } = row.original;

              return (
                <Button
                  color="primary"
                  variant="outlined"
                  size="small"
                  disabled={
                    !isViewJobDialogAvailable({ jobId, caseId, modality })
                  }
                  onClick={handleViewJobClick({
                    jobId,
                    caseId,
                    dbName,
                    modality,
                  })}
                >
                  View Job
                </Button>
              );
            },
          },
        ],
      },
    ];
  }, [isAnnotatorLoading, annotatorOptions, dbName, modality]);

  return (
    <Vertical>
      {project.claim.type === 'Standard' ? (
        <AddCaseDialog
          open={isDialogVisible}
          projectId={projectId}
          onClose={handleCloseAddCaseDialog}
          onUpdate={handleUpdateAddCase}
          modality={modality}
        />
      ) : (
        <AddCPCCasesDialog
          open={isDialogVisible}
          projectId={projectId}
          onClose={handleCloseAddCaseDialog}
          onUpdate={handleUpdateAddCase}
          modality={modality}
        />
      )}

      {isViewJobDialogAvailable(jobDialogState) && (
        <Suspense fallback={null}>
          <ViewJobDialog
            onClose={handleViewJobDialogClose}
            {...jobDialogState}
          />
        </Suspense>
      )}

      <FilterPanel>
        <Button
          color="primary"
          variant="contained"
          onClick={handleClickAddCases}
          disabled={project.confirmed}
          startIcon={<AddIcon />}
          style={{ width: 150 }}
        >
          Add Cases
        </Button>

        <AnnotatorFilter options={annotatorOptions} onChange={initPage} />
        <JobTypeFilter
          state={jobTypeOption}
          setState={setJobTypeOption}
          onChange={initPage}
        />
        <JobStateFilter
          state={stateOption}
          setState={setStateOption}
          onChange={initPage}
        />
      </FilterPanel>

      <Table<JobReadSchema>
        columns={jobsColumnHeader}
        data={jobs}
        isLoading={jobListState === 'loading'}
        isError={jobListState === 'hasError'}
        onRefresh={refreshJobList}
        paginationProps={{
          page,
          count: countTotalPages,
          onChange: handleChangePage,
        }}
      />
    </Vertical>
  );
}
