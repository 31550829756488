import { UserSchema } from 'src/types/api/auth';
import { SelectOption } from 'src/types/client/ui';
import { User } from 'src/types/client/user';

function getModality(dbName: string): string {
  if (dbName.includes('breast')) return 'MMG';
  else if (dbName.includes('chest')) return 'CXR';
  else if (dbName.includes('cct')) return 'CCT';
  else if (dbName.includes('dbt')) return 'DBT';

  return '';
}

export const getMappedUser = (user: UserSchema): User => {
  return {
    id: user.id,
    username: user.username,
    name: user.name,
    isAdmin: user.is_admin,
    DBAuthorizationList: user.permittedDatabases.map(dbName => {
      return {
        name: dbName,
        modality: getModality(dbName),
      };
    }),
    defaultDatabase: user.defaultDatabase,
  };
};

export const getDbOptions = (user: User): SelectOption[] => {
  return user.DBAuthorizationList.map(db => ({
    label: db.modality,
    value: db.name,
  }));
};
