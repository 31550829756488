/* eslint-disable no-template-curly-in-string */

import { INITIAL_CLAIM } from 'src/configs/claims';
import {
  CLAIM_CONTROLS,
  CLAIM_CONTROL_POLYGON,
} from 'src/configs/claims/controls';
import { ClaimCreateSchema } from 'src/types/api/data/project';

const CXRClaim: ClaimCreateSchema = {
  ...INITIAL_CLAIM,
  controls: [CLAIM_CONTROL_POLYGON, ...CLAIM_CONTROLS],
  viewer: {
    type: 'xray',
    images: [
      {
        name: 'frontal',
        id: 'frontal._id',
        height: 'frontal.Rows',
        width: 'frontal.Columns',
        path: 'frontal.path',
      },
      {
        name: 'lateral',
        id: 'lateral._id',
        height: 'lateral.Rows',
        width: 'lateral.Columns',
        path: 'lateral.path',
      },
    ],
    expandable: false,
    scroll: 'zoom',
    preview: {
      line: '',
      point: '',
      polygon: "`${alias('polygonType')} [${index}]`",
    },
  },
};

export default CXRClaim;
