import { ProjectJobStatsSchema, StatsType } from 'src/types/api/data/stats';
import {
  DailyStatsProps,
  GetProjectStatProps,
  GetStatProps,
  JobStats,
  ProjectDailyJobStats,
} from 'src/types/client/stats';
import { oneMonthAgo, today } from 'src/utils/date';
import { dataApi } from 'src/utils/http';

export const getProjectJobStats = async ({
  projectId,
  dbName,
}: GetProjectStatProps): Promise<JobStats> => {
  const statsTypes: StatsType[] = ['total', 'annotation', 'corroboration'];
  const stats = await Promise.all(
    statsTypes.map(type => getJobStats({ projectId, type, dbName }))
  );
  const result = stats.reduce(
    (acc, stat, idx) =>
      !!statsTypes[idx]
        ? {
            ...acc,
            [statsTypes[idx] as string]: stat.project,
          }
        : acc,
    {} as JobStats
  );

  return result;
};

// Get job stat of specific project
export const getJobStats = async ({
  projectId,
  annotatorId,
  type,
  dbName,
}: GetStatProps): Promise<ProjectJobStatsSchema> => {
  const { data } = await dataApi.get(`/projects/${projectId}/stats/v2/`, {
    params: {
      database_name: dbName,
      type: type === 'total' ? '' : type,
      user: annotatorId,
    },
  });
  return data;
};

export const getDailyJobStats = async ({
  projectId,
  type,
  dbName,
  dateFrom,
  dateTo,
}: DailyStatsProps): Promise<ProjectDailyJobStats> => {
  const { data } = await dataApi.get(`/projects/${projectId}/daily-stats/`, {
    params: {
      database_name: dbName,
      type: type === 'total' ? '' : type,
      date_from: dateFrom ? dateFrom : oneMonthAgo,
      date_to: dateTo ? dateTo : today,
    },
  });

  return data;
};
