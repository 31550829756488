import {
  useState,
  ChangeEvent,
  useMemo,
  useLayoutEffect,
  useCallback,
} from 'react';

import {
  AssetGroupEnum,
  AssetFormEnum,
  SelectorAttributes,
  TogglerAttributes,
} from '@lunit-io/radiology-data-interface';
import { chromeDark, InspectorThemeDefinition } from 'react-inspector';

import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import Check from '@mui/icons-material/Check';
import Delete from '@mui/icons-material/Delete';
import Refresh from '@mui/icons-material/Refresh';
import AlertTitle from '@mui/material/AlertTitle';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

import SelectorAttributesBuilder from 'src/components/AssetTemplateForm/SelectorAttributesBuilder';
import TogglerAttributesBuilder from 'src/components/AssetTemplateForm/TogglerAttributesBuilder';
import ExpandableObjectInspector from 'src/components/ExpandableObjectInspector';
import RowButtonGroup from 'src/components/Form/RowButtonGroup';
import FormBox from 'src/components/FormBox';
import AlertPanel from 'src/pages/NewProject/AlertPanel';
import {
  AssetTemplateFormSchema,
  DEFAULT_ASSET_CATEGORY,
} from 'src/types/client/asset';
import { SelectOption } from 'src/types/client/ui';
import AssetValidationUtils from 'src/utils/assetValidation';
import { Tuple } from 'src/utils/typeHelpers';

import AutoFillButton from './AutoFillButton';
import KeyNameAutoFillButton from './KeyNameAutoFillButton';
import StyledTextField from './StyledTextField';

const InspectorOpt: Partial<InspectorThemeDefinition> = {
  BASE_FONT_SIZE: '1rem',
  TREENODE_FONT_SIZE: '1rem',
  TREENODE_PADDING_LEFT: '1rem',
  BASE_BACKGROUND_COLOR: 'transparent',
};

type AssetFormOption = SelectOption<AssetFormEnum>;
type AssetGroupOption = SelectOption<AssetGroupEnum>;

const assetFormTypes: Tuple<AssetFormOption, 3> = [
  { value: 'texter', label: 'Texter' },
  { value: 'toggler', label: 'Toggler' },
  { value: 'selector', label: 'Selector' },
];

const assetGroupTypes: Tuple<AssetGroupOption, 6> = [
  { value: 'decision', label: 'Decision' },
  { value: 'finding/multiFramePolygon', label: 'Finding/multiFramePolygon' },
  { value: 'finding/polygon', label: 'Finding/polygon' },
  { value: 'finding/line', label: 'Finding/line' },
  { value: 'finding/point', label: 'Finding/point' },
  { value: 'finding/box', label: 'Finding/box' },
];

const DEFAULT_GETTER_PREFIX = 'decision.';

const DEFAULT_ASSET: AssetTemplateFormSchema = {
  form: assetFormTypes[0].value,
  group: assetGroupTypes[0].value,
  getter: { key: '' },
  setter: { key: '', update: {} },
  name: '',
  text: '',
};

const { getCategories } = AssetValidationUtils;

interface Props {
  existingAssetId?: string;
  existingAsset?: AssetTemplateFormSchema;
  onConfirm?: (newAsset: AssetTemplateFormSchema, id?: string) => void;
  onDelete?: () => void;
  readonly?: boolean;
}

const AssetTemplateForm = ({
  existingAssetId,
  existingAsset,
  onConfirm,
  readonly = false,
}: Props): JSX.Element => {
  const [newAsset, setNewAsset] =
    useState<AssetTemplateFormSchema>(DEFAULT_ASSET);

  const initializeNewAsset = useCallback(() => {
    setNewAsset(existingAsset ? existingAsset : DEFAULT_ASSET);
  }, [existingAsset]);

  // Sets newAsset to existing asset template or blank when page renders.
  useLayoutEffect(() => {
    initializeNewAsset();
  }, [initializeNewAsset]);

  const getterPrefix = useMemo<string>(() => {
    return `${
      newAsset.group === 'decision' ? DEFAULT_GETTER_PREFIX : 'findings.@'
    }`;
  }, [newAsset.group]);

  const handleGroupChange = (event: SelectChangeEvent<AssetGroupEnum>) => {
    const group = event.target.value as AssetGroupEnum;
    setNewAsset(prev => ({
      ...prev,
      group,
    }));
  };

  const handleFormChange = (event: SelectChangeEvent<AssetFormEnum>) => {
    setNewAsset(prev => {
      const form = event.target.value as AssetFormEnum;
      switch (form) {
        case 'selector': {
          return {
            ...prev,
            form,
            formAttributes: {
              allowEmpty: false,
              allowMultiSelect: false,
              categories: [DEFAULT_ASSET_CATEGORY],
            } as SelectorAttributes,
          };
        }
        case 'toggler': {
          return {
            ...prev,
            form,
            formAttributes: {
              yes_alias: '',
              no_alias: '',
            } as TogglerAttributes,
          };
        }
        case 'texter':
        default: {
          delete prev.formAttributes;
          return {
            ...prev,
            form,
          };
        }
      }
    });
  };

  const handleAssetTextChange = (event: ChangeEvent<HTMLInputElement>) => {
    return setNewAsset(prev => ({ ...prev, text: event.target.value }));
  };

  const setAssetName = (newAssetName: string) => {
    setNewAsset(prev => ({
      ...prev,
      name: newAssetName,
    }));
  };

  const handleAssetNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    return setAssetName(event.target.value);
  };

  const handleGetterChange = (event: ChangeEvent<HTMLInputElement>) => {
    return setNewAsset(prev => ({
      ...prev,
      getter: { ...prev.getter, key: event.target.value },
    }));
  };

  const handleSetterChange = (event: ChangeEvent<HTMLInputElement>) => {
    return setNewAsset(prev => ({
      ...prev,
      setter: {
        ...prev.setter,
        key: event.target.value,
      },
    }));
  };

  const handleUpdateFieldNameChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    return setNewAsset(prev => ({
      ...prev,
      setter: {
        ...prev.setter,
        // eslint-disable-next-line no-template-curly-in-string
        update: { [event.target.value]: '${VALUE}' },
      },
    }));
  };

  const handleConfirm = () => {
    onConfirm?.(newAsset, existingAssetId);
  };

  const handleDelete = async () => {
    try {
      alert(
        `1. Add logic to delete Asset Template
2. If successful, go back to Asset Template page
3. If not, add snackbar: 
🚨"Could not delete asset template. Please try again."`
      );
    } catch {
      alert(
        '1. add snackbar: "Could not delete asset template. Please try again."'
      );
    }
  };

  const handleClickNameAutoFill = (name: string) => {
    setAssetName(name);
  };

  const getterAutoFillSuggestion = `${getterPrefix}${newAsset.name}`;

  const handleClickGetterAutoFill = () => {
    setNewAsset(prev => ({
      ...prev,
      getter: { ...prev.getter, key: getterAutoFillSuggestion },
    }));
  };

  const setterAutoFillSuggestion = getterPrefix.replace('.', '');

  const handleClickSetterAutoFill = () => {
    setNewAsset(prev => ({
      ...prev,
      setter: {
        ...prev.setter,
        key: setterAutoFillSuggestion,
      },
    }));
  };

  const handleClickUpdateFieldNameAutoFill = () => {
    setNewAsset(prev => ({
      ...prev,
      setter: {
        ...prev.setter,
        // eslint-disable-next-line no-template-curly-in-string
        update: { [newAsset.name]: '${VALUE}' },
      },
    }));
  };

  /**
   * It would be replaced with more stable way with react-hook-form.
   * https://lunit.atlassian.net/browse/APSS-90
   */
  const isValid = useMemo(() => {
    const isFormAttributeValid = (() => {
      switch (newAsset.form) {
        case 'selector': {
          return getCategories(newAsset).every(({ alias, ...required }) =>
            Object.values(required).every(val => !!val)
          );
        }
        case 'toggler':
        case 'texter':
        default: {
          return true;
        }
      }
    })();

    const isSetterUpdateFieldNameValid = (() => {
      if (
        !newAsset?.setter?.update ||
        typeof newAsset.setter.update !== 'object'
      ) {
        return false;
      }
      const setterUpdateProperties = Object.keys(newAsset.setter.update);
      return setterUpdateProperties.every(
        prop =>
          // TODO: add proper input validation because "{}" is currently valid
          !!prop
      );
    })();

    return (
      !!newAsset.name &&
      !!newAsset.text &&
      !!newAsset.getter.key &&
      !!newAsset.setter.key &&
      isSetterUpdateFieldNameValid &&
      isFormAttributeValid
    );
  }, [newAsset]);

  return (
    <Container>
      {existingAssetId && !readonly && (
        <AlertPanel severity="error">
          <AlertTitle>Be very, very careful here!</AlertTitle>
          <ul>
            <li>
              Editing this asset template can have unintended consequences.
            </li>
            <li>
              Note that most project designers may not review every
              configuration in extreme detail, and they'll likely use the same
              asset templates repeatedly.
            </li>
            <li>
              Changing this asset template will not update past projects. Due to
              this, you could break consistency between annotation projects's
              readings depending on how you edit the template.
            </li>
            <li>
              Be <em>extremely</em> careful about the{' '}
              <strong>
                <code>"Getter Key"</code>
              </strong>{' '}
              ,{' '}
              <strong>
                <code>"Setter Key"</code>
              </strong>{' '}
              , and{' '}
              <strong>
                <code>"Update Field Name"</code>
              </strong>{' '}
              fields, because these control how readings are read from and
              written to the database.
            </li>
            <li>
              After reading this, if you're not comfortable with making your
              desired changes, please contact AIP to help you accomplish your
              goal.
            </li>
          </ul>
          <Typography></Typography>
        </AlertPanel>
      )}

      <FormBox title="Basic">
        <FormControl fullWidth size="small">
          <StyledTextField
            disabled
            size="small"
            label="ID"
            value={
              existingAssetId
                ? existingAssetId
                : 'ID will be assigned when created'
            }
          />
        </FormControl>
        <FormControl fullWidth size="small">
          <InputLabel id="asset-group">Asset group</InputLabel>
          <Select
            labelId="asset-group"
            value={newAsset.group}
            label="Asset group"
            required
            onChange={handleGroupChange}
            disabled={readonly}
          >
            {assetGroupTypes.map(groupOption => (
              <MenuItem value={groupOption.value} key={groupOption.value}>
                {groupOption.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </FormBox>
      <FormBox>
        <FormGroup
          sx={{ flex: 1, gap: 1, flexDirection: 'row', flexWrap: 'nowrap' }}
        >
          <FormControl fullWidth size="small">
            <StyledTextField
              size="small"
              label="Display Text"
              required
              value={newAsset.text}
              onChange={handleAssetTextChange}
              disabled={readonly}
            />
          </FormControl>
          <FormControl fullWidth size="small">
            <StyledTextField
              size="small"
              label="Asset Name (primary key)"
              required
              value={newAsset.name}
              onChange={handleAssetNameChange}
              disabled={readonly}
              InputProps={{
                endAdornment: !readonly && (
                  <KeyNameAutoFillButton
                    text={newAsset.text}
                    onClick={handleClickNameAutoFill}
                  />
                ),
              }}
            />
          </FormControl>
        </FormGroup>
      </FormBox>

      {!existingAssetId && (
        <AlertPanel>
          <AlertTitle>
            Be careful with the values below{' '}
            <span role="img" aria-label="emoji of hand pointing down">
              👇
            </span>
          </AlertTitle>
          <Typography>
            These fields control how data is read from and written to the
            database. If you're not familiar or comfortable with setting this,
            just click <AutoFixHighIcon /> for each field. The default values
            are good for most cases. If you need more advanced behavior, please
            contact AIP.
          </Typography>
        </AlertPanel>
      )}
      <FormBox>
        <FormControl fullWidth size="small">
          <StyledTextField
            size="small"
            label="Getter Key"
            required
            value={newAsset.getter.key}
            onChange={handleGetterChange}
            disabled={readonly}
            InputProps={{
              endAdornment: !readonly && (
                <AutoFillButton
                  suggestion={getterAutoFillSuggestion}
                  onClick={handleClickGetterAutoFill}
                />
              ),
            }}
          />
        </FormControl>
      </FormBox>
      <FormBox>
        <FormGroup
          sx={{ flex: 1, gap: 1, flexDirection: 'row', flexWrap: 'nowrap' }}
        >
          <FormControl fullWidth size="small">
            <StyledTextField
              size="small"
              label="Setter Key"
              required
              value={newAsset.setter.key}
              onChange={handleSetterChange}
              disabled={readonly}
              InputProps={{
                endAdornment: !readonly && (
                  <AutoFillButton
                    suggestion={setterAutoFillSuggestion}
                    onClick={handleClickSetterAutoFill}
                  />
                ),
              }}
            />
          </FormControl>
          <FormControl fullWidth size="small">
            <StyledTextField
              size="small"
              label="Update Field Name"
              required
              value={Object.keys(newAsset?.setter?.update as object) || ''}
              onChange={handleUpdateFieldNameChange}
              disabled={readonly}
              InputProps={{
                endAdornment: !readonly && (
                  <AutoFillButton
                    suggestion={newAsset.name}
                    onClick={handleClickUpdateFieldNameAutoFill}
                  />
                ),
              }}
            />
          </FormControl>
        </FormGroup>
      </FormBox>

      <FormBox title="Form">
        <FormGroup sx={{ flex: 1, gap: 2 }}>
          <FormControl fullWidth size="small">
            <InputLabel id="asset-form">Asset form</InputLabel>
            <Select
              labelId="asset-form"
              value={newAsset.form}
              label="Asset form"
              onChange={handleFormChange}
              disabled={readonly}
            >
              {assetFormTypes.map(formOption => (
                <MenuItem value={formOption.value} key={formOption.value}>
                  {formOption.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </FormGroup>
      </FormBox>

      {newAsset.form === 'selector' && (
        <SelectorAttributesBuilder
          asset={newAsset}
          setAsset={setNewAsset}
          readonly={readonly}
        />
      )}

      {newAsset.form === 'toggler' && (
        <TogglerAttributesBuilder
          asset={newAsset}
          setAsset={setNewAsset}
          readonly={readonly}
        />
      )}

      <FormBox title="Final Asset">
        <ExpandableObjectInspector
          theme={{ ...chromeDark, ...InspectorOpt }}
          data={newAsset}
          collapsedLevel={1}
        />
      </FormBox>
      {!readonly && (
        <Grid container spacing={3} justifyContent="flex-end">
          <Grid item>
            <RowButtonGroup>
              {existingAsset && existingAssetId && (
                <Button
                  color="error"
                  variant="outlined"
                  startIcon={<Delete />}
                  onClick={handleDelete}
                  disabled={true}
                >
                  Delete
                </Button>
              )}
              <Button
                color="info"
                variant="contained"
                startIcon={<Refresh />}
                onClick={initializeNewAsset}
              >
                Reset
              </Button>
              <Button
                onClick={handleConfirm}
                disabled={!isValid}
                color="primary"
                variant="contained"
                startIcon={<Check />}
              >
                Confirm
              </Button>
            </RowButtonGroup>
          </Grid>
        </Grid>
      )}
    </Container>
  );
};

export default AssetTemplateForm;

const Container = styled('div')(
  ({ theme }) => `
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing(1)};
`
);
