import { ClaimAssetValidationSchema } from '@lunit-io/radiology-data-interface';
import { useDrag } from 'react-dnd';

import Close from '@mui/icons-material/Close';
import SubdirectoryArrowRight from '@mui/icons-material/SubdirectoryArrowRight';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';

import FormListItemBase from 'src/pages/NewProject/FormListItemBase';
import {
  AssetValidationDropResult,
  ASSET_VALIDATION_DROP_TYPE,
  ASSET_VALIDATION_ITEM_TYPE,
} from 'src/types/client/asset';

import ValidationOneOf from './Validations//ValidationOneOf';
import ValidationIfSet from './Validations/ValidationIfSet';

interface Props {
  type: ASSET_VALIDATION_ITEM_TYPE;
  index?: number;
  item: ClaimAssetValidationSchema;
  onAdd?: () => void;
  onDelete?: (index: number) => void;
  onClickButton?: (index: number) => void;
  isValid?: boolean;
}

const AssetValidationItem = ({
  type,
  index = 0,
  item,
  onAdd,
  onDelete,
  onClickButton,
  isValid = true,
}: Props): JSX.Element => {
  const [{ handlerId, isDragging, canDrag }, drag] = useDrag({
    type,
    item: () => ({
      index,
    }),
    canDrag: () => {
      return type !== ASSET_VALIDATION_ITEM_TYPE.CONFIGURED;
    },
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult<AssetValidationDropResult>();

      if (!dropResult) {
        return;
      }

      if (dropResult.type === ASSET_VALIDATION_DROP_TYPE.ADDING) {
        onAdd?.();
      }
    },
    collect: monitor => ({
      canDrag: monitor.canDrag(),
      isDragging: monitor.isDragging(),
      handlerId: monitor.getHandlerId(),
    }),
  });

  const handleDelete = () => {
    onDelete?.(index);
  };

  const getHandleClick = (onClickButton: Props['onClickButton']) => {
    if (!onClickButton) {
      return undefined;
    }
    return () => onClickButton(index);
  };

  const renderValidation = (
    key: keyof ClaimAssetValidationSchema
  ): JSX.Element | undefined => {
    switch (key) {
      case 'ifSet': {
        return (
          <ValidationIfSet
            item={item[key]}
            onClick={getHandleClick(onClickButton)}
          />
        );
      }
      case 'oneOf': {
        return (
          <ValidationOneOf
            item={item[key]}
            onClick={getHandleClick(onClickButton)}
          />
        );
      }
      default: {
        return undefined;
      }
    }
  };

  return (
    <div data-handler-id={handlerId}>
      <FormListItemBase
        ref={drag}
        $isDragging={isDragging}
        $isDraggable={canDrag}
        $isValid={isValid}
      >
        <Container>
          <Validations>
            {Object.keys(item).map((key, index) => (
              <Validation key={key} $borderTop={index !== 0}>
                {index !== 0 && (
                  <NestedIcon>
                    <SubdirectoryArrowRight fontSize="inherit" />
                  </NestedIcon>
                )}
                {renderValidation(key)}
              </Validation>
            ))}
          </Validations>
          {onDelete && (
            <ButtonWrap>
              <IconButton onClick={handleDelete}>
                <Close fontSize="small" />
              </IconButton>
            </ButtonWrap>
          )}
        </Container>
      </FormListItemBase>
    </div>
  );
};

export default AssetValidationItem;

const Container = styled('div')(
  ({ theme }) => `
  display: flex;
`
);

const Validations = styled('div')(
  ({ theme }) => `
  flex: 1;
`
);

const Validation = styled('div')<{ $borderTop: boolean }>(
  ({ theme, $borderTop }) => `
  display: flex;
  align-items: center;
  gap: ${theme.spacing(0.5)};
  padding: ${theme.spacing(1.5)};
  border-width: ${$borderTop ? '1px 0 0' : 0};
  border-style: solid;
  border-color: ${theme.custom.bgLight};
`
);

const NestedIcon = styled('div')(
  ({ theme }) => `
  color: ${theme.custom.text};
`
);

const ButtonWrap = styled('div')(
  ({ theme }) => `
  border-left: 1px solid ${theme.custom.bgLight};
`
);
