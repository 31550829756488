import { CSSProperties, ReactNode } from 'react';

import styled from 'styled-components';

interface Props {
  children: ReactNode;
  style?: CSSProperties;
}

const FilterPanel = ({ children, style }: Props): JSX.Element => {
  return <Container style={style}>{children}</Container>;
};

export default FilterPanel;

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.75rem;
`;
