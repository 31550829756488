import { useMemo } from 'react';

import { useHistory, useRouteMatch } from 'react-router-dom';
import { useRecoilRefresher_UNSTABLE, useRecoilValue } from 'recoil';

import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

import { updateAssetTemplate } from 'src/api/assetTemplates';
import { Vertical } from 'src/components/Alignments';
import AssetTemplateForm from 'src/components/AssetTemplateForm';
import { SectionTitleWithBreadcrumbs } from 'src/components/Section';
import useAlertSnackbar from 'src/hooks/useAlertSnackbar';
import {
  assetTemplateState,
  useRefreshAssetTemplatesList,
} from 'src/states/assetTemplates';
import { myDBNameState } from 'src/states/myInfo';
import { AssetTemplateReadSchema } from 'src/types/api/data/assetTemplate';
import { AssetTemplateFormSchema } from 'src/types/client/asset';
import { PathNames } from 'src/types/client/url';
import {
  ASSET_TEMPLATES_PAGE_TITLE,
  MANAGE_ASSET_TEMPLATE_TITLE,
} from 'src/utils/constants';
import { UrlUtil } from 'src/utils/url';

const ManageAssetTemplate = (): JSX.Element => {
  const history = useHistory();
  const { openAlertSnackbar } = useAlertSnackbar();
  const refreshAssetTemplateList = useRefreshAssetTemplatesList();
  const {
    params: { modalityLabel },
  } = useRouteMatch<{ modalityLabel: string }>();
  const dbName = useRecoilValue(myDBNameState(modalityLabel));
  const {
    params: { assetTemplateId },
  } = useRouteMatch<{
    assetTemplateId: string;
  }>();

  const refreshCurrentAssetTemplate = useRecoilRefresher_UNSTABLE(
    assetTemplateState.current({ assetTemplateId, dbName })
  );

  const assetTemplate = useRecoilValue(
    assetTemplateState.current({ assetTemplateId, dbName })
  );

  const assetTemplateForm = useMemo(() => {
    const mapToAssetTemplateFormSchema = (
      assetTemplate: AssetTemplateReadSchema
    ): AssetTemplateFormSchema => {
      const { id, createdAt, ...assetTemplateForm } = assetTemplate;
      return assetTemplateForm;
    };

    return mapToAssetTemplateFormSchema(assetTemplate);
  }, [assetTemplate]);

  const goToAssetTemplatesPage = () => {
    history.push(
      `${UrlUtil.getUrl(PathNames.ASSET_TEMPLATES, {
        modalityLabel,
      })}`
    );
  };

  const handleConfirm = async (asset: AssetTemplateFormSchema, id?: string) => {
    try {
      if (!id) {
        throw new Error('Tried to update an asset template without an id.');
      }

      await updateAssetTemplate(id, asset, dbName);

      refreshAssetTemplateList();
      refreshCurrentAssetTemplate();
      openAlertSnackbar({
        severity: 'success',
        description: 'Successfully updated asset template',
      });
    } catch (error) {
      openAlertSnackbar({
        severity: 'error',
        description: 'Failed to update asset template. Please try again',
      });
    }
    goToAssetTemplatesPage();
  };

  return (
    <Vertical>
      <SectionTitleWithBreadcrumbs>
        <Link
          underline="hover"
          color="inherit"
          variant="h5"
          onClick={goToAssetTemplatesPage}
          sx={{ cursor: 'pointer' }}
        >
          {ASSET_TEMPLATES_PAGE_TITLE}
        </Link>
        <Typography color="text.primary" variant="h5">
          {MANAGE_ASSET_TEMPLATE_TITLE}
        </Typography>
      </SectionTitleWithBreadcrumbs>
      <AssetTemplateForm
        onConfirm={handleConfirm}
        existingAssetId={assetTemplate.id}
        existingAsset={assetTemplateForm}
      />
    </Vertical>
  );
};

export default ManageAssetTemplate;
