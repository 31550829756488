import { Tabs } from 'react-tabs';

import { styled } from '@mui/material/styles';

const StyledTabs = styled(Tabs)(
  ({ theme }) => `
  & > ul {
    list-style-type: none;
  }

  .react-tabs {
    display: flex;
  }

  .react-tabs__tab-list {
    display: flex;
    flex-direction: row;
    padding: 0;
    margin-bottom: 0;
    border-bottom: 1px solid rgba(255,255,255,.1);
  }

  .react-tabs__tab {
    position: relative;
    bottom: -2px;
    cursor: pointer;
    padding: .75rem 1.5rem 1rem;
    border-radius: .5rem .5rem 0 0;
    border-color: transparent;
    border-width: 1px 1px 0;
    border-style: solid;
  }

  .react-tabs__tab--selected {
    color: ${theme.custom.textActive};
    background-color: ${theme.custom.bgLight};
    box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
    border-color: rgba(255,255,255,.1);
  }

  .react-tabs__tab-panel {
    position: relative;
    padding: 1.5rem;
    background-color: ${theme.custom.bgLight};
    box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
    border-color: rgba(255,255,255,.1);
    border-width: 0 1px 1px;
    border-style: solid;
  }
`
);

export default StyledTabs;
