import Fade from '@mui/material/Fade';
import { styled } from '@mui/material/styles';

import StyledSpinner from 'src/components/Spinner';

const TransitionSpinner = (): JSX.Element => {
  return (
    <SpinnerContainer>
      <Fade in={true} style={{ transitionDelay: '250ms' }}>
        <SpinnerBox>
          <StyledSpinner
            size={30}
            data-test-id="transitionTableLoadingSpinner"
          />
        </SpinnerBox>
      </Fade>
    </SpinnerContainer>
  );
};

export default TransitionSpinner;

const SpinnerContainer = styled('div', { name: 'SpinnerWrap' })`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SpinnerBox = styled('div', { name: 'SpinnerBox' })`
  padding: 1rem;
  border-radius: 0.25rem;
`;
