import {
  useState,
  useEffect,
  ChangeEvent,
  MouseEventHandler,
  useRef,
} from 'react';

import { Redirect, RouteComponentProps } from 'react-router-dom';
import { Transition } from 'react-transition-group';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { css, styled } from '@mui/material/styles';

import { login } from 'src/api';
import { Flexbox } from 'src/components/Alignments';
import Spinner from 'src/components/Spinner';
import { PathNames } from 'src/types/client/url';
import { ClientError } from 'src/utils/clientError';
import cookieHelper from 'src/utils/cookieHelper';
import { UrlUtil } from 'src/utils/url';

type StateProp = {
  state: string;
};

const duration = 750;

const Animation = (props: { state: string }) => css`
  transform: translate3d(0, ${props.state === 'exited' ? '-30px' : 0}, 0);
  opacity: ${props.state === 'exited' ? 0 : 1};
  transition:
    opacity ${duration}ms ease-out,
    transform ${duration}ms ease-out;
`;

const PageWrapper = styled('div')`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  * {
    font-size: 14px;
  }
`;

const Logo = styled('h1')<StateProp>`
  margin-bottom: 4rem;
  text-align: center;
  ${Animation}
  img {
    width: auto;
    max-width: 100%;
  }
`;
const Form = styled('form')<StateProp>`
  ${Animation}
  transition-delay: 600ms;
`;

const LoginFooter = styled('div')<StateProp>`
  ${Animation}
  margin-top: 3rem;
  text-align: center;
  transition-delay: 600ms;
  p {
    margin: 6px 0;
  }
  a {
    color: #89a1b5;
    text-decoration: none;
    &.email {
      color: #0ca1c6;
    }
  }
`;

const Notice = styled('p')`
  margin-top: 2rem;
  color: #808088;
  text-align: center;
  em {
    color: #a1a1ac;
    font-style: normal;
  }
`;

const ErrorContainer = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 1rem;
`;

// TODO: apply yup, react-hook-form
export default function Login({ history }: RouteComponentProps): JSX.Element {
  const [trigger, setTrigger] = useState(false);
  const nodeRef = useRef(null);

  const [usernameInput, setUsernameInput] = useState('');
  const [passwordInput, setPasswordInput] = useState('');

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState();

  useEffect(() => {
    setTrigger(true);
  }, []);

  const handleSubmit: MouseEventHandler<HTMLButtonElement> = async event => {
    event.preventDefault();
    if (isLoading) {
      return;
    }
    setIsLoading(true);
    try {
      await login({ username: usernameInput, password: passwordInput });
      history.replace(UrlUtil.getUrl(PathNames.HOME));
    } catch (error) {
      const err = error as ClientError;

      const message = (() => {
        if (error instanceof ClientError) {
          return (
            err.originalError?.response.data?.detail ||
            err.originalError?.message ||
            err.message
          );
        }
        return err.message;
      })();

      setErrorMessage(message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleInputUsername = (e: ChangeEvent<HTMLInputElement>) => {
    setUsernameInput(e.target.value);
    setErrorMessage(undefined);
  };

  const handleInputPassword = (e: ChangeEvent<HTMLInputElement>) => {
    setPasswordInput(e.target.value);
    setErrorMessage(undefined);
  };

  if (cookieHelper.hasAccessToken()) {
    return <Redirect to={UrlUtil.getUrl(PathNames.HOME)} />;
  }
  return (
    <PageWrapper>
      <Transition in={trigger} timeout={duration} nodeRef={nodeRef}>
        {state => (
          <div>
            <Logo state={state}>
              <img
                src={`${process.env.PUBLIC_URL}/icon-lunit-insight-cxr@2x.png`}
                alt=" Lunit Tagging Tool for Pathology"
              />
            </Logo>

            <Form state={state}>
              <Flexbox $direction="column" style={{ gap: '0.5rem' }}>
                <TextField
                  id="username"
                  size="small"
                  variant="outlined"
                  placeholder="Username"
                  autoComplete="username"
                  value={usernameInput}
                  fullWidth
                  onChange={handleInputUsername}
                  error={Boolean(errorMessage)}
                />
                <TextField
                  id="password"
                  size="small"
                  variant="outlined"
                  type="password"
                  placeholder="Password"
                  autoComplete="current-password"
                  value={passwordInput}
                  fullWidth
                  onChange={handleInputPassword}
                  error={Boolean(errorMessage)}
                />
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  onClick={handleSubmit}
                  disabled={
                    !(
                      usernameInput &&
                      !errorMessage &&
                      passwordInput &&
                      !isLoading
                    )
                  }
                >
                  {isLoading ? <Spinner size={26} /> : 'Login'}
                </Button>
                <ErrorContainer>
                  {errorMessage && (
                    <Typography
                      variant="subtitle1"
                      component="div"
                      data-view="error"
                      color="error"
                    >
                      {errorMessage}
                    </Typography>
                  )}
                </ErrorContainer>
              </Flexbox>
              <Notice>
                Lunit CTL Office is optimized to run on the{' '}
                <em>Chrome browser</em>.
              </Notice>
            </Form>
            <LoginFooter state={state}>
              <p>Admin for Lunit CTL Office</p>
              <p>
                Contact{' '}
                <a className="email" href="mailto:contact@lunit.io">
                  contact@lunit.io
                </a>
              </p>
              <p>Powered by Lunit Inc. {new Date().getFullYear()}</p>
            </LoginFooter>
          </div>
        )}
      </Transition>
    </PageWrapper>
  );
}
