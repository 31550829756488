import { useState, useEffect, SyntheticEvent } from 'react';

import { useHistory, useRouteMatch } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import Equalizer from '@mui/icons-material/Equalizer';
import Refresh from '@mui/icons-material/Refresh';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';

import { getReducedCases } from 'src/api';
import { SectionTitleWithTooltip } from 'src/components';
import { Vertical } from 'src/components/Alignments';
import ToggleButtons from 'src/components/Button/ToggleButton';
import FilterPanel from 'src/components/FilterPanel';
import RowButtonGroup from 'src/components/Form/RowButtonGroup';
import DropdownMenu from 'src/components/Input/DropdownMenu';
import { datasetState } from 'src/states/dataset';
import { myModalityOptionState } from 'src/states/myInfo';
import { CaseSearchQueryConfig } from 'src/types/api/data/case';
import { DatasetReadSchema } from 'src/types/api/data/dataset';
import { SelectOption } from 'src/types/client/ui';
import { PathNames } from 'src/types/client/url';
import { throwUnexpectedWrongModalityError } from 'src/utils/clientError';
import { DEFAULT_PAGE_SIZE } from 'src/utils/constants';
import { MutableTuple } from 'src/utils/typeHelpers';
import { UrlUtil } from 'src/utils/url';

import AIPieChart from './PieChart';

const predictionChartFill = [
  {
    match: {
      id: 'Exist',
    },
    id: 'dots',
  },
  {
    match: {
      id: 'Non-Exist',
    },
    id: 'dots',
  },
];

const priorityChartFill = [
  {
    match: {
      id: 'Done',
    },
    id: 'dots',
  },
  {
    match: {
      id: 'Partially Done',
    },
    id: 'dots',
  },
  {
    match: {
      id: 'Not Performed',
    },
    id: 'dots',
  },
];

type PriorityCountItems = MutableTuple<PriorityCountItem, 3>;
const emptyCounts: PriorityCountItems = [
  {
    id: 'Done',
    label: 'Done',
    value: 0,
  },
  {
    id: 'Partially Done',
    label: 'Partially Done',
    value: 0,
  },
  {
    id: 'Not Performed',
    label: 'Not Performed',
    value: 0,
  },
];

// TODO: replace these types with Pie Type
interface PredictionCountItem {
  id: string;
  label: 'Exist' | 'Non-Exist';
  value: number;
}
interface PriorityCountItem {
  id: string;
  label: 'Done' | 'Partially Done' | 'Not Performed';
  value: number;
}

/**
 *
 * @returns
 */
const AI = (): JSX.Element => {
  const {
    params: { modalityLabel },
  } = useRouteMatch<{ modalityLabel: string }>();
  const history = useHistory();

  const myDbOptionList = useRecoilValue(myModalityOptionState);
  const [selectedModality, setSelectedModality] = useState<SelectOption>({
    value: '',
    label: '',
  });
  const [selectedDataset, setSelectedDataset] = useState<SelectOption>();

  const dataset = useRecoilValue(
    datasetState.dataset({
      dbName: selectedModality.value,
      id: selectedDataset?.value || '',
    })
  );

  const allDataset = useRecoilValue(
    datasetState.allDataset({
      dbName: selectedModality.value,
    })
  );

  const datasetOpts = useRecoilValue(
    datasetState.allDatasetOptionList({
      dbName: selectedModality.value,
    })
  );

  const [predictionCounts, setPredictionCounts] = useState<
    PredictionCountItem[]
  >([]);
  const [priorityCounts, setPriorityCounts] = useState<PriorityCountItem[]>([]);

  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [isAnalyzed, setIsAnalyzed] = useState<boolean>(false);

  const handleChangeModality = (opt: SelectOption) => {
    history.push(
      UrlUtil.getUrl(PathNames.AI_OPERATIONAL, {
        modalityLabel: opt.label,
      })
    );
    setIsAnalyzed(false);
  };

  const handleChangeDataset = (
    event: SyntheticEvent,
    opt: SelectOption | null
  ) => {
    setSelectedDataset(opt || undefined);
  };

  const handleClickDatasetReset = () => {
    setSelectedDataset(undefined);
  };

  useEffect(() => {
    if (!myDbOptionList.length) {
      return;
    }
    const opt = myDbOptionList.find(({ label }) => label === modalityLabel);
    if (!opt) {
      throw throwUnexpectedWrongModalityError(modalityLabel);
    }
    setSelectedModality(opt);
    setSelectedDataset(undefined);
  }, [modalityLabel, myDbOptionList]);

  // search for the cases of selectedModality and selectedDataset, then set stats of AI prediction and priority.
  const handleClickApplyQuery = async () => {
    if (!!selectedModality) {
      const predictionSearchConfig = {
        modality: selectedModality.label,
        dbName: selectedModality.value,
        ...(!!selectedDataset && { datasetName: selectedDataset.label }),
        readingType: 'prediction',
      } as CaseSearchQueryConfig;

      if (!dataset) {
        return;
      }

      setIsSearching(true);
      setIsAnalyzed(false);

      const getNumSearched = async (
        searchConfig: CaseSearchQueryConfig
      ): Promise<number> => {
        // TODO: The logic below is a complete tragedy.
        // We should ask a new api and get things properly done.
        // get countTotalPages first
        const { countTotalPages } = await getReducedCases({
          ...searchConfig,
          page: 1,
        });
        // get number of whole
        const { cases: tailCases } = await getReducedCases({
          ...searchConfig,
          page: countTotalPages,
        });

        const numWholeSearched =
          Math.max(0, countTotalPages - 1) * DEFAULT_PAGE_SIZE +
          tailCases.length;
        return numWholeSearched;
      };

      const caseLength = dataset.cases?.length || 0;

      const numWholeCases =
        (!!selectedDataset
          ? caseLength
          : allDataset?.reduce(result => result + caseLength, 0)) || 0;
      const numPredictionCases = await getNumSearched(predictionSearchConfig);

      // TODO: Currently the priorities are simply ordered, abandoning previous 1~5 grading concept.
      // after making some criteria to give intuition of their "importance", add feature here.
      // Here it only divides them into 'Done', 'Partially Done', 'Not Performed'.
      const priorityCountsFromDataSet = (
        dataset: DatasetReadSchema
      ): PriorityCountItems => {
        if (!!dataset.priority) {
          const orderLists = dataset.priority.map(record => record.order || []);
          const numFullPrioritized = (dataset.cases || []).filter(objectId =>
            orderLists.every(orderList => orderList.includes(objectId))
          ).length;
          const notPrioritized = (dataset.cases || []).filter(objectId =>
            orderLists.every(orderList => !orderList.includes(objectId))
          ).length;
          const partiallyDone =
            (dataset.cases || []).length - numFullPrioritized - notPrioritized;

          return [
            {
              id: 'Done',
              label: 'Done',
              value: numFullPrioritized,
            },
            {
              id: 'Partially Done',
              label: 'Partially Done',
              value: partiallyDone,
            },
            {
              id: 'Not Performed',
              label: 'Not Performed',
              value: notPrioritized,
            },
          ];
        } else {
          return [
            {
              id: 'Done',
              label: 'Done',
              value: 0,
            },
            {
              id: 'Partially Done',
              label: 'Partially Done',
              value: 0,
            },
            {
              id: 'Not Performed',
              label: 'Not Performed',
              value: caseLength,
            },
          ];
        }
      };

      const newPriorityCounts =
        (!!selectedDataset
          ? priorityCountsFromDataSet(dataset)
          : allDataset?.reduce((result, dataset): PriorityCountItems => {
              const tmpPriorityCounts = priorityCountsFromDataSet(dataset);
              return [
                {
                  id: 'Done',
                  label: 'Done',
                  value: result[0].value + tmpPriorityCounts[0].value,
                },
                {
                  id: 'Partially Done',
                  label: 'Partially Done',
                  value: result[1].value + tmpPriorityCounts[1].value,
                },
                {
                  id: 'Not Performed',
                  label: 'Not Performed',
                  value: result[2].value + tmpPriorityCounts[2].value,
                },
              ];
            }, emptyCounts)) || [];

      const newPredictionCounts = [
        {
          id: 'Exist',
          label: 'Exist',
          value: numPredictionCases,
        },
        {
          id: 'Non-Exist',
          label: 'Non-Exist',
          value: numWholeCases - numPredictionCases,
        },
      ] as PredictionCountItem[];

      setPredictionCounts(newPredictionCounts);
      setPriorityCounts(newPriorityCounts);

      setIsSearching(false);
      setIsAnalyzed(true);
    }
  };

  return (
    <>
      <Vertical>
        <SectionTitleWithTooltip tooltip={TooltipDescription}>
          AI Operational
        </SectionTitleWithTooltip>

        <FilterPanel>
          <ToggleButtons
            options={myDbOptionList}
            value={selectedModality}
            onChangeLabel={handleChangeModality}
          />
          <DropdownMenu
            label="Select a dataset"
            options={datasetOpts}
            onChange={handleChangeDataset}
            value={selectedDataset}
            sx={{ minWidth: '20rem' }}
          />
          <Button
            variant="contained"
            onClick={handleClickDatasetReset}
            startIcon={<Refresh />}
          >
            Reset
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleClickApplyQuery}
            startIcon={<Equalizer />}
          >
            Analyze
          </Button>
        </FilterPanel>

        {selectedModality ? (
          !isSearching ? (
            isAnalyzed ? (
              <StatView>
                {!!predictionCounts && (
                  <PiePanel>
                    <AIPieChart
                      data={predictionCounts}
                      pieChartFill={predictionChartFill}
                    />
                  </PiePanel>
                )}
                {!!priorityCounts && (
                  <PiePanel>
                    <AIPieChart
                      data={priorityCounts}
                      pieChartFill={priorityChartFill}
                    />
                  </PiePanel>
                )}
              </StatView>
            ) : (
              <StatErrorView />
            )
          ) : (
            <StatErrorView>Loading...</StatErrorView>
          )
        ) : (
          <StatErrorView>Invalid Modality</StatErrorView>
        )}

        <Grid container spacing={3} justifyContent="flex-end">
          <Grid item>
            <RowButtonGroup style={{ minWidth: 400 }}>
              <Button color="primary" disabled variant="contained">
                Run AI Prediction
              </Button>

              <Button color="primary" disabled variant="contained">
                Prioritize Cases
              </Button>
            </RowButtonGroup>
          </Grid>
        </Grid>
      </Vertical>
    </>
  );
};

export default AI;

const PiePanel = styled('div')`
  width: 400px;
  height: 400px;
`;

const StatView = styled('div')(({ theme }) => ({
  backgroundColor: theme.custom.bgDark,
  width: '100%',
  margin: '8px 0 8px',
  display: 'flex',
  justifyContent: 'space-around',
}));

const StatErrorView = styled('div')(({ theme }) => ({
  backgroundColor: theme.custom.bgDark,
  margin: '8px 0 8px',
  minHeight: 300,
  fontSize: '24pt',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const TooltipDescription = (
  <div>
    It consists of:
    <br />
    1. Execution of AI prediction,
    <br />
    2. Analysis in them.
    <br />
    3. Prioritize the cases in order to make annotation projects efficiently.
  </div>
);
