import { ChangeEvent, Dispatch, SetStateAction } from 'react';

import { TogglerAttributes } from '@lunit-io/radiology-data-interface';

import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import TextField from '@mui/material/TextField';

import FormBox from 'src/components/FormBox';
import { AssetTemplateFormSchema } from 'src/types/client/asset';

interface Props {
  asset: AssetTemplateFormSchema;
  setAsset: Dispatch<SetStateAction<AssetTemplateFormSchema>>;
  readonly: boolean;
}

const TogglerAttributesBuilder = ({
  asset,
  setAsset,
  readonly,
}: Props): JSX.Element => {
  const getHandleChange =
    (key: keyof TogglerAttributes) =>
    (event: ChangeEvent<HTMLInputElement>) => {
      setAsset(prev => {
        if (prev.form !== 'toggler') {
          return prev;
        }
        return {
          ...prev,
          formAttributes: {
            ...prev.formAttributes,
            [key]: event.target.value,
          },
        };
      });
    };

  return (
    <FormBox title="Form Attributes &gt; Categories">
      <FormGroup
        sx={{ flex: 1, gap: 2, flexDirection: 'row', flexWrap: 'nowrap' }}
      >
        <FormControl fullWidth size="small">
          <TextField
            size="small"
            label="Alias for positive"
            value={asset.formAttributes?.yes_alias || ''}
            onChange={getHandleChange('yes_alias')}
            disabled={readonly}
          />
        </FormControl>
        <FormControl fullWidth size="small">
          <TextField
            size="small"
            label="Alias for negative"
            value={asset.formAttributes?.no_alias || ''}
            onChange={getHandleChange('no_alias')}
            disabled={readonly}
          />
        </FormControl>
      </FormGroup>
    </FormBox>
  );
};

export default TogglerAttributesBuilder;
