import { ClaimAnnotationResponse } from '@lunit-io/radiology-data-interface';

import { dataApi } from 'src/utils/http';

export type GetAnnotationProps = {
  jobId: string;
  dbName: string;
};

// Get jobs of project
export const getAnnotation = async ({
  jobId,
  dbName,
}: GetAnnotationProps): Promise<ClaimAnnotationResponse> => {
  const { data } = await dataApi.get<ClaimAnnotationResponse>(
    `/jobs/${jobId}/annotation/`,
    {
      params: {
        database_name: dbName,
      },
    }
  );

  return data;
};
