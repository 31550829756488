import { useState, useEffect } from 'react';

import { useRouteMatch, useHistory } from 'react-router-dom';
import { useRecoilValueLoadable } from 'recoil';
import styled from 'styled-components';

import LoadingButton from '@mui/lab/LoadingButton';

import { SectionTitle } from 'src/components';
import { Vertical } from 'src/components/Alignments';
import CaseSearchQueryForm from 'src/components/Form/CaseSearchQueryForm';
import QueryResultListView from 'src/components/ListView/QueryResultListView';
import { caseState } from 'src/states/case';
import {
  CaseSearchQueryConfig,
  ReducedCaseReadSchema,
} from 'src/types/api/data/case';
import { SelectOption } from 'src/types/client/ui';
import { PathNames } from 'src/types/client/url';
import { UrlUtil } from 'src/utils/url';

const emptyCases: ReducedCaseReadSchema[] = [];

export default function CaseSearch(): JSX.Element {
  // Pagination
  const [page, setPage] = useState(1);
  const {
    params: { modalityLabel },
  } = useRouteMatch<{ modalityLabel: string }>();
  const history = useHistory();

  // Save query results
  const [resultItems, setResultItems] =
    useState<ReducedCaseReadSchema[]>(emptyCases);
  const [searchConfig, setSearchConfig] = useState<CaseSearchQueryConfig>({
    modality: '',
    readingType: '',
  });

  const { state, contents } = useRecoilValueLoadable(
    caseState.reducedCaseList({
      ...searchConfig,
      page,
      pageSize: searchConfig.modality === 'CXR' ? 5 : 1,
    })
  );

  const isSearching = state === 'loading';

  const { cases, countTotalPages } =
    state === 'hasValue' ? contents : { cases: emptyCases, countTotalPages: 1 };

  const disableLoadMore = resultItems.length === countTotalPages;

  const handleClickSearch = (config: CaseSearchQueryConfig) => {
    if (JSON.stringify(config) !== JSON.stringify(searchConfig)) {
      setPage(1);
      setResultItems(emptyCases);
      setSearchConfig(config);
    }
  };

  const handleClickLoadMore = async () => {
    setPage(p => p + 1);
  };

  const handleChangeModality = (opt: SelectOption) => {
    setResultItems(emptyCases);
    history.push(
      UrlUtil.getUrl(PathNames.CASE_SEARCH, {
        modalityLabel: opt.label,
      })
    );
  };

  useEffect(() => {
    setResultItems(prev => [...prev, ...cases]);
  }, [cases]);

  return (
    <Vertical>
      <SectionTitle>Case Search</SectionTitle>
      <Container>
        <CaseSearchQueryForm
          onSubmit={handleClickSearch}
          isSearching={isSearching}
          onChangeModality={handleChangeModality}
          modality={modalityLabel}
          isModalityChangeable={true}
        />
        {!isSearching && resultItems.length !== 0 && (
          <div>
            {resultItems.length}/{countTotalPages} loaded.
          </div>
        )}
        <QueryResultListView items={resultItems} />
        <LoadingButton
          color="primary"
          variant="contained"
          loading={isSearching}
          loadingPosition="center"
          onClick={handleClickLoadMore}
          disabled={disableLoadMore}
        >
          Load More
        </LoadingButton>
      </Container>
    </Vertical>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;
