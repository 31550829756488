import { ChangeEvent, useMemo } from 'react';

import { ClaimCorroborationRuleSchema } from '@lunit-io/radiology-data-interface';
import { useRecoilState } from 'recoil';

import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';

import { Flexbox } from 'src/components/Alignments';
import FormBox from 'src/components/FormBox';
import { INITIAL_ASSIGN_RULE } from 'src/configs/claims';
import { newProjectState } from 'src/states/newProject';
import { ValueOf } from 'src/utils/typeHelpers';

const CorroborationForm = (): JSX.Element => {
  const [claim, setClaim] = useRecoilState(newProjectState.claim);

  const { probability, maxCount, finishWhenAnnotationDone } = useMemo(() => {
    return {
      probability: claim?.assignRule?.corroboration?.probability ?? 0,
      maxCount: claim?.assignRule?.corroboration?.max ?? 0,
      finishWhenAnnotationDone:
        claim?.assignRule?.corroboration?.finishWhenAnnotationDone ?? false,
    };
  }, [claim]);

  const setCorroboration = (
    key: keyof ClaimCorroborationRuleSchema,
    value: ValueOf<ClaimCorroborationRuleSchema>
  ) => {
    setClaim(prev => ({
      ...prev,
      assignRule: {
        ...prev.assignRule,
        corroboration: {
          ...INITIAL_ASSIGN_RULE.corroboration,
          ...prev.assignRule?.corroboration,
          [key]: value,
        },
      },
    }));
  };

  const handleChangeProbability = (e: ChangeEvent<HTMLInputElement>) => {
    const parsed = parseFloat(e.target.value) ? parseFloat(e.target.value) : 0;
    const prob = parsed > 1 ? 1 : parsed;
    setCorroboration('probability', prob);
  };

  const handleChangeMaxCount = (e: ChangeEvent<HTMLInputElement>) => {
    const parsedValue = parseInt(e.target.value, 10);
    const max = parsedValue ? parsedValue : 0;
    setCorroboration('max', max);
  };

  const handleChangeFinishWhenAnnotationDone = (
    e: ChangeEvent<HTMLInputElement>
  ) => {
    setCorroboration('finishWhenAnnotationDone', e.target.checked);
  };

  return (
    <Flexbox style={{ gap: '1rem' }} $direction="column">
      <FormBox title="Corroboration">
        <Flexbox style={{ gap: '1rem' }}>
          <TextField
            size="small"
            label="Probability"
            variant="outlined"
            placeholder="Probability"
            value={probability}
            onChange={handleChangeProbability}
            type="number"
            inputProps={{
              step: 0.001,
              min: 0,
              max: 1,
              'data-test-id': 'newProjectInput-probability',
            }}
            fullWidth
          />
          <TextField
            size="small"
            label="Max Count"
            variant="outlined"
            placeholder="Max Count"
            value={maxCount}
            onChange={handleChangeMaxCount}
            fullWidth
            inputProps={{ 'data-test-id': 'newProjectInput-maxCount' }}
          />
        </Flexbox>
      </FormBox>
      <FormBox>
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                checked={finishWhenAnnotationDone}
                onChange={handleChangeFinishWhenAnnotationDone}
                inputProps={{ 'aria-label': 'controlled' }}
                data-test-id="newProjectInput-toggleFinishWhenAnnotationDone"
              />
            }
            label="This project can only be confirmed when all the annotations are done."
          />
        </FormGroup>
      </FormBox>
    </Flexbox>
  );
};

export default CorroborationForm;
