import { useState } from 'react';

import { useErrorHandler } from 'react-error-boundary';
import { useRecoilValue } from 'recoil';
import styled from 'styled-components';

import AccountCircleOutlined from '@mui/icons-material/AccountCircleOutlined';
import ExitToApp from '@mui/icons-material/ExitToApp';

import { logout } from 'src/api';
import MyAccountDialog from 'src/components/Modal/MyAccountDialog';
import { myInfoState } from 'src/states/myInfo';

import MenuItem from './MenuItem';

const Wrapper = styled.div`
  padding-bottom: 16px;
`;

const MenuItemTitle = styled.div`
  margin-left: 8px;
  color: #fff;
`;

const UserMenuList = (): JSX.Element | null => {
  const user = useRecoilValue(myInfoState);
  const [isDialogVisible, setIsDialogVisible] = useState<boolean>(false);
  const handleError = useErrorHandler();

  const handleClickLogout = () => {
    try {
      logout();
    } catch (e) {
      handleError(e);
    }
  };

  return (
    <Wrapper>
      <MenuItem onClick={() => setIsDialogVisible(true)}>
        <AccountCircleOutlined />
        <MenuItemTitle>{user.username}</MenuItemTitle>
      </MenuItem>
      <MenuItem onClick={handleClickLogout}>
        <ExitToApp />
        <MenuItemTitle>Logout</MenuItemTitle>
      </MenuItem>
      <MyAccountDialog
        open={isDialogVisible}
        onClose={() => setIsDialogVisible(false)}
        user={user}
      />
    </Wrapper>
  );
};

export default UserMenuList;
