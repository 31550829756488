import {
  JobListSchema,
  JobState,
  JobType,
  BulkAssignJobsSchema,
} from 'src/types/api/data/job';
import { dataApi } from 'src/utils/http';

interface AssignProps {
  projectId: string;
  userId: string;
  count: number;
  dbName: string;
}

export const assignJobsByProject = async ({
  projectId,
  userId,
  count,
  dbName,
}: AssignProps): Promise<BulkAssignJobsSchema> => {
  const payload = { count };
  const { data } = await dataApi.patch<BulkAssignJobsSchema>(
    `/projects/${projectId}/jobs/assign/`,
    payload,
    {
      params: {
        database_name: dbName,
        user: userId,
      },
    }
  );

  return data;
};
export type getJobsProps = {
  projectId: string;
  page: number;
  pageSize: number;
  filter?: string;
  dbName: string;
  annotatorId?: string;
  type?: JobType;
  states: JobState[];
};

// Get jobs of project
export const getJobs = async ({
  projectId,
  page,
  pageSize,
  filter,
  dbName,
  annotatorId,
  type,
  states,
}: getJobsProps): Promise<JobListSchema> => {
  const { data } = await dataApi.get<JobListSchema>(
    `/v2/projects/${projectId}/jobs/`,
    {
      params: {
        project: projectId,
        page,
        page_size: pageSize,
        database_name: dbName,
        annotator: annotatorId,
        filter,
        type,
        states,
      },
    }
  );

  return data;
};
