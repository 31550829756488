import { SyntheticEvent, useLayoutEffect, useState } from 'react';

import styled from 'styled-components';

import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';

import DropdownMenu from 'src/components/Input/DropdownMenu';
import MultiDropdownMenu from 'src/components/Input/MultiDropDownMenu';
import CommonDialog, {
  CommonDialogProps,
} from 'src/components/Modal/CommonDialog';
import useSelectedValueOptions from 'src/pages/NewProject/useSelectedValueOptions';
import { ClientClaimAssetCreateSchema } from 'src/types/client/asset';
import { SelectOption } from 'src/types/client/ui';

interface Props extends CommonDialogProps {
  asset?: ClientClaimAssetCreateSchema;
  onConfirm: (parentId: string, selectedValues: (string | boolean)[]) => void;
}

const SelectedValuesDialog = ({
  open,
  onClose,
  onConfirm,
  asset,
}: Props): JSX.Element => {
  const { options, allowMultiSelect } = useSelectedValueOptions(asset);

  const [selected, setSelected] = useState<SelectOption[]>([]);

  // Set the first option as a default value
  useLayoutEffect(() => {
    const initOptions = () => {
      setSelected(options[0] ? [options[0]] : []);
    };

    initOptions();
  }, [options]);

  const handleChange = (
    event: SyntheticEvent,
    value: SelectOption[] | SelectOption | null
  ) => {
    setSelected(() => {
      if (!value) {
        return [];
      }
      if (Array.isArray(value)) {
        return value;
      }
      return [value];
    });
  };

  const handleClickConfirm = async () => {
    if (!asset) {
      return;
    }
    onConfirm(
      asset?.id || '',
      selected.map(({ value }) => {
        if (value === 'true') {
          return true;
        }
        if (value === 'false') {
          return false;
        }
        return value;
      })
    );
  };

  return (
    <CommonDialog
      onClose={onClose}
      title={`Select target values`}
      open={open}
      fullWidth
    >
      <DialogContent>
        <Typography>
          Currently targeted parent asset is <strong>"{asset?.name}"</strong>{' '}
          asset.
        </Typography>
        <Typography>
          Please select the target values among the available values of this
          parent asset.
        </Typography>
        <DropdownContent>
          {allowMultiSelect ? (
            <MultiDropdownMenu
              label="Select values"
              options={options}
              onChange={handleChange}
              value={selected}
              openOptionsOnMounted
            />
          ) : (
            <DropdownMenu
              label="Select values"
              options={options}
              onChange={handleChange}
              value={selected[0]}
              openOptionsOnMounted
            />
          )}
        </DropdownContent>
        {!selected.length && (
          <Alert severity="warning">
            At least one value should be selected.
          </Alert>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleClickConfirm} disabled={selected.length <= 0}>
          Confirm
        </Button>
      </DialogActions>
    </CommonDialog>
  );
};

export default SelectedValuesDialog;

const DropdownContent = styled.div`
  margin: 16px 0;
`;
